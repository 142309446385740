#root{
    height: 100% !important;
}
.homestart{
    height: 100%;
}
/* Header section start */

.headerstart {
    position: fixed;
    padding: 0 46px;
    z-index: 99999;
    width: 100%;
    top: 0
    /* background-color: #00000066; */
    /* color: white; */
}
.sweet-loading{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sweet-loading .css-1oya24m-style-Loader{
    width: 50px;
    height: 50px;
}
.navullist a {
    color: unset;
}
.navullist a.active {
    color: unset;
}
.navullist a:hover{
    color: unset;
}
.headerlogo {
    margin-top: 14px;
    /* margin-bottom: 12px; */
}
.navullist {
    display: flex;
    list-style: none;
    align-items: center;
    font-size: 14px;
    /* color: #e4e4e4; */
}
ul.navullist li {
    margin-right: 41px;
    cursor: pointer;
}
button.ui.button.signinbtn:hover {
    font-size: 14px;
    padding: 6px 0;
    width: 68px;
    background-color: #006dc3 !important;
    color: white !important;
    border-color: transparent !important;
}
button.ui.button.signinbtn {
    font-size: 14px;
    padding: 6px 0;
    width: 68px;
    border: 1px solid;
    background-color: transparent;
    color: white;
}
img.headerlogoimg {
    width: 78%;
    height: auto;
}
.navitems {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
li.navbtn {
    margin-right: 22px !important;
}
.homenav {
    position: relative;
    cursor: pointer;
}
.locationnav{
    position: relative;
    cursor: pointer;
}
.locationnav:before {
    content: '';
    background-color: blue;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -96%;
    display: none;
}
.homenav:before {
    content: '';
    background-color: blue;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -96%;
    display: none;
}
li:hover{
    cursor: pointer;
}
li:hover.homenav:before,li:hover.locationnav:before{
    display: block;
}
.dropdwnbutnarea .ui.compact.menu{
    box-shadow: none !important;
    border: none !important;
    display: flex;
    align-items: center;
}
.ui.item.simple.dropdown.dropdwnbutn {
    font-size: 14px;
    padding: 6px;
    /* width: 120px; */
    border: none !important;
    /* border-radius: 25px !important; */
    height: 32px;
    background: transparent !important;
}
.usrimgarea {
    width: 27px;
    height: 27px;
    margin-left: 5px;
}
img.usericn {
    width: 100%;
}
.usericn.feedbackimg {
    margin-right: 12px;
    width: 20px;
    height: 20px;
    margin-top: 5px;
}
.menufeedback {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    padding: 12px;
    position: fixed;
    bottom: 5%;
    right: 3%;
    border-radius: 4px;
    box-shadow: 0 1px 20px 0px #0000002b;
    
}
.menufeedback:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: 0px;
	bottom: -13px;
	border: 12px solid;
	border-color: white white transparent transparent;
}
.feedbackdespotop {
    color:#212121
}
.drpdwn-inrarea{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #ffa500a6; */
    border: 1px solid #55608012;
    border-radius: 25px;
    /* width: 124px; */
}
.drpdwn-inrarea:hover {
    /* background-color: #0e4d9a; */
    /* color: white; */
    border: 1px solid #55608038;
}
.drpdwn-inrarea .menu.transition {
    width: 115px !important;
    box-shadow: 5px 4px 20px #0000003d;
    margin-left: -27px !important;
    border: none;
}
.ui.menu .drpdwn-inrarea .ui.dropdown .menu>.item,
.ui.menu .drpdwn-inrarea .ui.dropdown .menu>.active.item ,
.ui.menu .drpdwn-inrarea .ui.dropdown .menu>.selected.item {
    background-color: transparent !important;
}
.ui.menu .drpdwn-inrarea .ui.dropdown .menu>.item:hover {
    background-color: #d6d2d247 !important
}
button.ui.button.logout {
    background: transparent;
    padding: 0;
    margin: 0;
    font-weight: unset;
    color: #070707;
    font-size: 13px;
}
/* Header section end */


/* Carousel section start */
.carouselpart .image-container img.overlay{
    min-width: 100% !important;
}

.carouselpart .each-fade{
height: 600px;
}
.carouselpart{
    position: relative;
}
.carouselpart .react-slideshow-container + div.indicators {
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    /* margin-top: -7px; */
    position: absolute;
    bottom: 6%;
    z-index: 9;
}
.welcometext{
font-family: 'Poppins',sans-serif;
position: absolute;
background-color: #00000085;
height: 100%;
width: 100%;
top: 0;
color: white;
bottom: 0;
margin: 0;
z-index: 4;
}
.welcometext .ui.grid{
    height: 100%;
    width: 100%;
}
.welcometext .seven.wide.computer.twelve.wide.mobile.eight.wide.tablet.column{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-flow: column;
}
p.firstwelcome {
    font-weight: 100;
    font-size: 37px;
    text-align: left;
    margin-bottom: 0;
}
p.secondlinetext{
    font-size: 37px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0;
}
p.secondlinetext span.chargerspan {
    color: #f5b800;
}
p.descriptntext{
    font-size: 15px;
    color: #d6d6d6;
    text-align: left;
}
/* Crousel section end */


/* service section start */
.servicestart {
    padding: 75px 0;
    background-color: #03a9f40a;
}
img.serviceleftimg {
    height: 100%;
    width: 100%;
}
.serviceleft {
    height: 100%;
    width: 85%;
}
.servicestart .six.wide.computer.sixteen.wide.mobile.eight.wide.tablet.column {
    display: flex;
    align-items: center;
    justify-content: center;
}
p.sectionheader {
    font-size: 26px;
    color: #464646;
    position: relative;
    margin-left: 13px;
    margin-bottom: 25px;
}
p.sectionheader:before {
    content: '';
    background-color: #0661bc;
    height: 2px;
    width: 6%;
    position: absolute;
    bottom: 0;
}
img.thirdserviceicon {
    height: auto;
    width: 50%;
}
img.secondserviceicon {
    height: auto;
    width: 60%;
}
img.firstserviceicon {
    height: auto;
    width: 38%;
}
.serviceright .two.wide.computer.three.wide.mobile.three.wide.tablet.column {
    display: flex;
    align-items: center;
    justify-content: center;
}
.servicelist .ui.grid {
    display: flex;
    align-items: flex-start;
    margin-top: 6px;
}
p.servicelistheader {
    font-size: 15px;
    font-weight: 600;
    color: #464646;
    margin-bottom: 8px;
}
p.servicedetails {
    font-size: 14px;
    color: #bbbbbb;
}
/* service section end */


/* work section start */
.workstart{
    padding: 60px 0;
}
.worksectionheader{
    font-size: 26px;
    color: #464646;
    position: relative;
    margin-left: 13px;
    margin-bottom: 25px;
    text-align: center;
}
.worksectionheader:before {
    content: '';
    background-color: #0661bc;
    height: 2px;
    width: 6%;
    position: absolute;
    bottom: -9%;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.workstart p.servicelistheader {
    text-align: center;
    margin-bottom: 22px;
}
p.workdetails {
    font-size: 13px;
    text-align: center;
    color: #afafaf;
    margin-bottom: 35px;
    padding: 0 23px;
}
img.worlsecimg {
    width: 60%;
    height: auto;
}
.worklistsec{
    text-align: center;
}
/* work section end */



/* facility start sec  */
.startsec {
    background-color: #0661bc;
    color: white;
    padding: 37px 0;
}
.startsec .ui.container {
    display: flex;
    justify-content: space-between;
}
p.facilitytext {
    margin: 0;
    padding: 0 10px;
    font-size: 33px;
    letter-spacing: 1px;
}
button.ui.button.facilitystartbtn {
    background-color: white !important;
    font-size: 17px;
}
/* facility start sec end */

/* place sec start */
.placestart{
    padding: 60px 0;
    height: 543px;
    background-color: #0661bc0a;
}

p.aboutustext {
    margin: 0;
    font-size: 16px;
    color: #a7a6a6;
}
.placestart p.sectionheader {
    margin-left: 0;
}
button.ui.button.readmorebtn {
    margin-top: 40px;
    background-color: #0661bc;
    color: white;
    padding: 12px 0;
    width: 19%;
}
.imagestack .ui.card {
    width: 118px;
    box-shadow: 0 1px 15px #00000017;
    align-items: center;
    height: 112px;
    justify-content: center;
    margin: 0 auto;
}
p.placetext {
    text-align: center;
    font-size: 13px;
    color: #464646;
}
img.cardimg {
    height: auto;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 8px;
}
img.cardimg.restimg{
    width: 47%;
}
.imagestack .ui.card .extra.content {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: column;
    border: none !important;
}
.firstimagestack {
    z-index: 99999;
    position: relative;
}
.secondimagestack {
    position: absolute;
    right: 22%;
    top: 33%;
    z-index: 9999;
}
.thirdimagestack {

    position: absolute;
    top: 67%;
    z-index: 999;
    left: 30%;
}
.fourthimagestack {
    position: absolute;
    top: 95%;
    right: 22%;
    z-index: 99;
}
.fifthimagestack {

    position: absolute;
    top: 130%;
    z-index: 9;
    left: 30%;
}

/* place sec end */


/* footer sec start */
.footerstart .ui.grid {
    margin-left: 39px;
}
img.firstfootericon {
    height: auto;
    width: 100%;
}
.footerstart {
    background: 
   /* top, transparent red, faked with gradient */      
   linear-gradient( rgba(19, 18, 18, 0.96),rgba(14, 14, 14, 0.95)     ),         
    url(../../Images/footerbg.jpg) no-repeat;
    background-size: cover;
    color: white;
    padding: 45px 0;
}
p.footerhead {
    text-align: center;
    font-size: 25px;
    color: #e4e4e4;
    margin-bottom: 51px;
}
.footerstart p.servicelistheader {
    color: #d6eaff;
    font-size: 14px;
}
.footerstart p.servicedetails {
    margin-bottom: 3px;
}
/* footer sec end */

/* copyright footer start */
.copyfooterstart {
    padding: 15px 0;
    background-color: #2d2d2d;
}
p.copyrightsec {
    margin: 0;
    text-align: center;
    color: #8e8d8d;
    font-size: 13px;
}
/* copyright footer end */


/*---------------------- Responsive start----------------------- */

@media screen and (min-width: 320px) and (max-width: 768px){
    /* Header start */
ul.navullist li {
    margin-right: 20px;
}
li.navbtn {
    margin-right: 5px !important;
}
/* .navitems{
    display: unset;
}
.navullist{
    padding-left: 0px;
} */

.headerstart{
    padding: 5px 15px;
    bottom: 0px;
    top: unset;
    background: #000E59;
}
.headerlogo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 8px;
}
img.headerlogoimg {
    width: 60%;
    height: auto;
}
img.menuimg {
    height: auto;
    width: 10%;
}

.mobilenavitems ul.navullist {
    flex-flow: column;
    margin-top: 0;
    align-items: flex-start;
    font-size: 17px;
    margin-left: 17px;
    padding-left: 0;
}
.mobilenavitems ul.navullist li {
    margin-bottom: 20px;
}
.headerstart .ui.grid {
    margin-bottom: -1.5rem;
}
li:hover.homenav:before, li:hover.locationnav:before{
    display: none;
}

.usrimgarea {
    width:unset;
    height: unset;
    margin-left: 0;
}

img.usericn {
    width: 18px;
    height: 18px;
}
.usericn.feedbackimg {
    margin-right: 23px;
}
/* Header end */


/* Carousel sec start */
/* Carousel sec end */
.welcometext .ui.grid{
    justify-content: center;
    align-items: center;
}
p.firstwelcome{
    font-size: 28px;
}
p.secondlinetext{
    font-size: 27px;
}
p.descriptntext{
    font-size: 13px;
}
/* work sec start */
.worklistsec {
    margin-bottom: 50px;
}
.worksectionheader:before {
    width: 23%;
}
/* work sec end */

/* facility sec start */
.startsec .ui.container {
    display: flex;
    flex-flow: column;
    justify-content: center !important;
}
p.facilitytext {
    margin-bottom: 22px;
}
/* facility sec end */

/* service sec start */
.serviceright {
    align-items: center;
    display: flex;
    flex-flow: column;
}
button.ui.button.readmorebtn {
    width: 36%;
}
p.sectionheader:before {
    width: 51%;
}
/* service sec end */

/* place sec start */
.secondimagestack {
    position: absolute;
    right: 15%;
    top: 57%;
    z-index: 9999;
}
.thirdimagestack {
    position: absolute;
    top: 116%;
    z-index: 999;
    left: 28%;
}
.fourthimagestack {
    position: absolute;
    top: 169%;
    right: 14%;
    z-index: 99;
}
.fifthimagestack {
    position: absolute;
    top: 230%;
    z-index: 9;
    left: 29%;
}
.placestart{
    height: auto;
}
.placestart .serviceright {
    margin-top: 91%;
}
.placestart p.sectionheader:before {
    width: 32%;
}
/* place sec end */


/* footer sec start */
.footerstart .ui.grid {
    margin-left: 15px;
}
/* footer sec end */
}
