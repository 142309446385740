.toplogosec {
    margin-bottom: 0;
}
.toplogosec p {
    font-size: 30px;
    font-weight: 600;
    padding-right: 24px;
    margin: 0;
    color: #464545;
    margin-bottom: 5px;
}
img.landinglogoimg {
    width: 100%;
    height: 100%;
}
.landingpagelogodiv {
    width: 250px;
    height: auto;
    margin-bottom: 34px;
}
.landingpage{
    /* background-image:  linear-gradient(  rgba(253, 253, 255, 0.67), rgba(255, 254, 254, 0.85)),
url('../../Images/Shade_charger_bg.jpg'); */
background: url('../../Images/shade_charger_image_v2.jpg');
background-repeat: no-repeat;
background-size: cover;
background-position: right;

}
.landingpage .ui.container {
    padding-left: 70px;
}
button.ui.button.landingloginbutton {
    background: #0b4d9a;
    margin-right: 19px;
}
button.ui.button.landingloginbutton:hover {
    background: #073b77;
}
.formbutn.forlndnpage {
    margin-top: 50px;
}
.eight.column.row.pointsrow {
    width: 80% !important;
    /* margin: 0 auto; */
}
.powersecpoints li {
    margin-top: 18px;
    font-size: 15px;
    color: #565555; 
}
.sublandingpage{
/* padding: 55px 40px 40px 40px; */
    /* box-shadow: 0px 1px 20px #00000026; */
    /* background: #fff; */
    padding-right: 0px;
    height: auto;
    /* width: 922px; */
    margin: 0 auto;
}
.forlndnpage button.ui.button {
    width: 18%;
    font-size: 16px;
}
h3.powersecheading {
    font-size: 18px;
    color: #fbb931;
    margin-bottom: 17px;
    margin-top: 0;
    font-weight: 500;
}
ul.powersecpoints {
    margin: 0;
    padding: 0;
    list-style: none;
}
img.landingtickcls {
    width: 14px;
    margin-right: 8px;
    height: auto;
}
.landingpagetext {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 47%;
}
/*---------------------- Responsive start----------------------- */
@media screen and (min-width:320px) and (max-width:767px){
    .landingpage .ui.container {
        padding: 0;
        z-index: 99;
        margin: 0;
        width: 100% !important;
    }
    .landingpage {
        /* background-position: bottom right -217px; */
        /* display: block; */
        position: relative;
        background: linear-gradient(
            rgba(0, 0, 0, 0.7), 
            rgba(0, 0, 0, 0.7)
          ),url('../../Images/mobilebackimg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
    }
    .landingpagelogodiv {
        margin-bottom: 27px;
        width: 186px;
    }
    .toplogosec p {
        font-size: 28px;
        margin-bottom: 4px;
        color: #fff;
        line-height: 30px;
        font-weight: 400;
        font-family: 'Roboto';
    }
    h3.powersecheading {
        font-size: 17px;
        margin-bottom: 0;
    }
    .landingpagetext {
        display: block;
        width: 100%;
        margin-top: 40px;
    }
    .powersecpoints li {
        margin-top: 10px;
        color: #e2e0e6;
        font-weight: 500;
        font-size: 16px;
        font-family: 'Roboto';
        display: flex;
        align-items: center;
    }
    img.landingtickcls {
        margin-right: 15px;
        width: 17px;
    }
    .forlndnpage button.ui.button {
        width: 100%;
        border-radius: 2px;
        font-size: 17px;
    }
    .formbutn.forlndnpage {
        margin-top: 40px !important;
        margin-bottom: 0;
    }
    button.ui.button.landingloginbutton {
        margin-bottom: 14px;
        margin-right: 0;
        border-radius: 3px;
    }
    /* .overlaylanding {
        background: #ffffff73;
        width: 100%;
        height: 100%;
        position: absolute;
    } */
    
}
@media screen and (min-width:768px) and (max-width:1024px){
    .landingpagetext{
        width: 85%;
    }
    .forlndnpage button.ui.button {
        width: 30%;
        font-size: 18px;
    }
    .powersecpoints li {
        margin-top: 35px;
        font-size: 17px;
        color: #565555;
    }
    .toplogosec p{
        font-size: 35px;
        margin-bottom: 8px;
    }
    h3.powersecheading{
        font-size: 22px;
    }
    .landingpage {
        background: #f9fafc url(/static/media/shade_charger_image_01.1a517ba2.jpg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom right;
    }
    .landingpage .landingpagelogodiv.hidden-xs {
        display: block !important;
    }
}
/*---------------------- Responsive start----------------------- */