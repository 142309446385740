.adminmainstart {
    /* height: 100%; */
    background: #ebebeb;
}
.adminmainstart .headerstart{
    box-shadow: 0px 0px 8px 1px #00000021 !important;
    z-index: 9999999;
    background:#ffffff;
    bottom:unset;
    padding: 0 7px 0 30px
}
button.ui.button.adminvouchrbutn .plus.icon{
    opacity: 1;
}
.addsection .selection.dropdown i.icon.dropdown:before {
    content: "\f0d7";
    position: absolute;
    right: 6px;
}
/* .adminmainstart .MuiPaper-elevation2 > div.MuiToolbar-root{
    position: absolute;
    background: #ffffff;
    z-index: 99;
    width:100%;
    top:0;
} */
.field.companyadmin {
    padding-top: 16px;
}
.addbtnuseradmin{
    display: flex !important;
    align-items: flex-end
}
li.usernameemail {
    font-size: 14px;
    margin-bottom: 5px;
    color: #5c5c5c;
    font-family: 'Roboto', sans-serif;
}
.adminuserlisting ul {
    list-style: none;
    padding: 0;
}
.adminuserlisting {
    margin-top: 23px;
}
button.ui.button.adminuserdelete {
    height: 26px;
    padding: 6px;
    width: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent
}
button.ui.button.adminuserdelete:hover {
    background-color: #dfe0e0;
}
.deleteuserimg{
    height: 100%;
    width: 100%
}
/* .adminmainstart .MuiPaper-elevation2 > div:nth-child(2){
   padding-top: 64px;
   height: 100%;
   padding-bottom: 57px;
} */

/* .adminmainstart .MuiPaper-elevation2 > .MuiTable-root{
   position: absolute;
   bottom:0;
   width:100%;
} */
.adminmainstart .MuiPaper-elevation2{
    box-shadow: none !important;
    /* height: 100%; */
    position: relative !important;
}
.adminmainstart .MuiPaper-elevation2 tfoot{
    background:#ffffff;
    border-top:1px solid #eeeeee !important;
}
/* Header css start */

/* Header css end */
/* table list start */

.checkboxmaindiv {
    border: 1px dotted #dededf;
    border-radius: 4px;
    padding: 10px;
}
.checkboxitems .ui.checkbox  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}
.checkboxsecitems .ui.checkbox{
    display: flex;
    justify-content: space-between;
}
.checkboxitems .ui.checkbox input.hidden+label, .checkboxsecitems .ui.checkbox input.hidden+label{
    font-size: 12px;
}
.checkboxitems .ui.checkbox .box:before, .ui.checkbox label:before, .checkboxsecitems .ui.checkbox .box:before, .ui.checkbox label:before{
    width: 14px;
    height: 14px;
}
.checkboxitems .ui.checkbox .box:after, .ui.checkbox label:after, .checkboxsecitems .ui.checkbox .box:after, .ui.checkbox label:after{
    font-size: 11px;
    top: -1px;
    width: 14px;
    height: 14px;
}
.six.wide.computer.column.checkboxlist {
    padding-left: 0;
    
}
.eight.wide.computer.column.selectcogridsec {
    padding-right: 0;
}
.on-offbutton button.ui.button.onoffbuttoncss {
    margin: 0;
    width: 79%;
    padding: 8px 0px;
    background-color: #cc0000;
    color: white;
    font-size: 10px;
}
.two.wide.computer.column.on-offbutton {
    padding: 15px 15px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.ui.checked.checkbox.checkselectall label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
}
.ui.checkbox.checkselectall label{
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
}
/* edit new table switch css start */
    
    
    input {
        display: block;
        margin-bottom: 1.5em;
    }
    .two.wide.computer.column.on-offbutton input.light{
        font-size: 21px;
    }
    
    
    /* Switch */

    [type="checkbox"] {
      position: absolute;
      top: 59%;
    left: 66%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-transition: all .05s ease-in-out;
      transition: all .05s ease-in-out;
      width: 31px;
      height: 47px;
      z-index: 2;
      opacity: 0.001;
      cursor: pointer;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      outline: none;
    }
    [type="checkbox"] ~ .switch {
      position: absolute;
      top: 59%;
    left: 66%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-transition: all .05s ease-in-out;
      transition: all .05s ease-in-out;
      width: 31px;
      height: 47px;
      border-radius: 12px;
      background: -webkit-gradient(linear, left top, left bottom, from(#d2d4d6), to(#fff));
      background: linear-gradient(to bottom, #d2d4d6, #fff);
      box-shadow: inset 0 -2px 4px rgba(212, 205, 199, 0.75), inset 0 -8px 0 1px rgba(156, 156, 157, 0.85), 0 0 0 1px rgba(116, 116, 118, 0.8), 0 6px 6px rgba(41, 41, 41, 0.3), 0 0 0 4px #d4d7d8;
      -webkit-filter: contrast(1);
              filter: contrast(1);
    }
    [type="checkbox"] ~ .switch::before, [type="checkbox"] ~ .switch::after {
      content: 'on';
      position: absolute;
      left: 0;
      bottom: 11px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 8px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
      -webkit-transition: all .05s ease-in-out;
      transition: all .05s ease-in-out;
    }
    [type="checkbox"] ~ .switch::after {
      content: 'off';
      bottom: auto;
      top: 5px;
      color: rgba(0, 0, 0, 0.45);
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
    }
    [type="checkbox"]:checked ~ .switch {
      background: -webkit-gradient(linear, left top, left bottom, from(#a1a2a3), to(#f0f0f0));
      background: linear-gradient(to bottom, #a1a2a3, #f0f0f0);
      box-shadow: inset 0 2px 4px rgba(212, 205, 199, 0.75), inset 0 8px 0 1px rgba(255, 255, 255, 0.77), 0 0 0 1px rgba(116, 116, 118, 0.8), 0 -2px 2px rgba(41, 41, 41, 0.18), 0 0 0 4px #d4d7d8;
      -webkit-filter: none;
              filter: none;
    }
    [type="checkbox"]:checked ~ .switch::before {
      bottom: 3px;
      color: rgba(0, 0, 0, 0.5);
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    }
    [type="checkbox"]:checked ~ .switch::after {
      top: 13px;
      color: rgba(0, 0, 0, 0.45);
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.15);
    }

[type="checkbox"] ~.led-box {
    position: absolute;
    top: 60px;
    left: 0px;
    margin: 0 auto;
    width: 24px;
    height: 24px;
    opacity: 0.2;
    background-color: #afafaf;   
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #383838 0 -1px 9px, rgba(226, 226, 226, 0.5) 0 2px 12px
  }
  [type="checkbox"]:checked ~ .led-red {
    opacity: 1;
    background-color: #F00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
  }

    

    /* ======= */
    /* edit new table switch css end */

/* .checkboxitems .ui.radio.checkbox .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after,.checkboxsecitems .ui.radio.checkbox .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after
{
    background-color: rgb(139, 195, 74);

} */

.ui.toggle.checkbox.testrelaytoggle input:checked ~ .box::before, .ui.toggle.checkbox.testrelaytoggle input:checked ~ label::before {

    background-color: 

    #469a00 !important;

}
.lightsettingsarea{
    margin-top: 20px
}
.lightsettingbuttongroup {
    margin-top: 15px;
    width: 100%;
}

button.ui.button.activebtncss.onoffbuttoncss {
    background-color: #4e9000;
    color: white;
}
button.ui.button.tableonoffbtn {
    font-size: 11px;
    padding: 6px 0;
    margin-left: 12px;
    width: 40px;
}
button.ui.button.onoffbuttoncss {
    margin: 0;
    width: 13%;
    padding: 12px 0;
    background-color: #cc0000;
    color: white;
}
button.ui.button.onbtncss.tableonoffbtn {
    background-color: #4e9000;
    color: white;
}
.tablenamedivcss {
    display: flex;
    align-items: center;
}
p.tablenumbertext {
    margin: 0;
}
 .wifistatusonline {
    background-color: #83e2837a;
    color: #3b8c1e;
    padding: 3px 7px;
    border-radius: 2px;
    width:auto;
    font-size:12px;
}

.wifistatusoffline {
    background-color: #ff00003d;
    color: red;
    padding: 3px 7px;
    border-radius: 2px;
    width:auto;
    font-size:12px;
}
.wifistatushalf {
    background-color: #ffeb3b85;
    color: #dc8e00;
    padding: 3px 7px;
    border-radius: 2px;
    width:auto;
    font-size:12px;
}
.field.companyselect {
    margin-left: 30px;
    margin-top: 30px;
}
.ui.selection.dropdown.companyinputselect {
    margin-left: 10px;
    width: 24%;
}
.labelcompany {
    font-size: 14px;
    font-weight: 600;
}

.tablediv {
    padding:30px;
    height: 100%;
    position: relative;
}
.tablediv .MuiToolbar-gutters{
    padding-left: 14px;
    border-bottom: 1px solid #eeeeee;
}
.tablediv tfoot .MuiToolbar-gutters, .tablediv tfoot .MuiTableCell-root{
    border-bottom: none;
}
.tablediv .MuiTypography-h6{
    font-size: 22px;
}
.tablediv table thead th span{
    font-size: 13px;
    color: #3c3c3c !important;
}
.MuiTableRow-head th{
    background-color: #f7f7f7;
    z-index: 1;
}
.tableliststart .ui.form .field>.selection.dropdown {
    min-width: 100%;
}
.ui.selection.dropdown.selectcompany {
    margin: 0;
}
.ui.form .field>.selection.dropdown>.dropdown.icon {
    float: right;
}
.ui.selection.dropdown.companyinputselect.selectcompany >.dropdown.icon:before {
    position: absolute;
    right: 4%;
}


.tableliststart .profilepagestart {
    margin-top: 0;
    padding: 67px 0 0 0;
    height: 100%;
}
.tableliststart {
    height: 100%;
}


.tableliststart .historytablestart {
    margin-top: 0;
}

.tableliststart .historytablestart .payment-heading {
    padding: 14px 0px 18px 4px;
    /* background: #083d7d; */
}

.tableliststart .historytablestart .payment-heading h2:before {
    bottom: -10px;
}
.tableliststart .historytablestart .payment-heading h2, 
.tableliststart .historytablestart .payment-heading h2.headingtxt {
    position: relative;
    padding: 0 49px;
    color: #fff !important;
}


.tableliststart .historytablestart .payment-heading h2:before {
    position: unset;
    content: '';
    background-color: #fbb83d;
    height: 4px;
    width: 7%;
    bottom: -2px;
}


.tableliststart .payment-heading h2 img {
    width: 25px;
    height: 25px;
    margin-right: 12px;
    vertical-align: sub;
}
input[type="date"]::-webkit-inner-spin-button, 
input[type="date"]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.blackoutdiv input {
    border: 1px solid #dfe0e0 !important;
    border-radius: 3px !important;
    margin-left: 10px !important;
}
.blackoutdiv {
    display: flex;
    align-items: center;
}
.location-area.blackoutpmain {
    margin-bottom: 30px;
}
.tblelistimg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.addtblelistmain {
    background-color: #f8f8f8;
    margin-bottom: 34px;
    padding: 25px;
    position: relative;
}
.addtblelistmain a.ui.label {
    line-height: 0;
    padding: 6px 0 4px 17px !important;
}
.addtblelistmain i.delete.icon {
    padding: 11px;
}
.addtblelistmain .deleteservc {
    position: absolute;
    top: 8px;
    right: 2px;
}
button.ui.button.tbledeletebtn {
    font-size: 12px;
    background: #ff7b7b;
    padding: 7px 7px;
    color: #fff;
}
.addtblelistmain .ui.form input[type=number] {
    background: #f8f8f8;
}

/* table list end */

/* --------list of table start------------- */


/* --------list of table end--------------- */




/* ---------------------adminsettings Page start---------------------- */
.adminsettingstart {
    height: 100%;
    
}

.settingsheadin {
    padding: 80px 51px;
}

.settingsheadin h3 {
    font-size: 35px;
    color: #002452;
    display: inline-block;
    position: relative;
}

.settingsheadin h3::before {
    position: absolute;
    content: '';
    background: #ffa600;
    width: 49%;
    height: 3px;
    bottom: -3px;
}

.settingsdiv {
    padding: 17px 23px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

.settingstext p {
    margin-bottom: 22px;
    font-size: 18px;
    color: #777;
    border-left: 3px solid #fbb93152;
    padding-left: 20px;   
}
.settingstext span {
    font-size: 17px;
    color: #212121;
    font-weight: 600;
    margin-left: 5px;

}
.mb0 p {
    margin-bottom: 0;
}



.settingstext.mb0 span {
    display: inline-block;
    justify-content: center;
}

.servicehead h3 {
    font-size: 22px;
    margin-bottom: 16px;
    color: #002f6b;
    letter-spacing: 0.5px;
}

.servicehead {
    position: relative;
    display: inline-block;
}

.servicehead::before {
    position: absolute;
    content: '';
    background: #ec8c03;
    width: 38%;
    height: 2px;
    right: -38px;
    bottom: 29px;
}

/* ----------------------adminsettings page end----------------------- */

/* Admin Voucher List Start---------- */
.addvouchr-butnarea {
    text-align: right;
    padding-right: 45px;
}
.voucherform {
    /* box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 
    0px 2px 2px 0px rgba(0,0,0,0.14),
    0px 3px 1px -2px rgba(0,0,0,0.12);
    background: #fff; */
    height: auto;
    width: 100%;
    margin: 20px auto 0;
    padding: 0px;
}
.field.deatils-textarea textarea{
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px !important;
    padding: 5px;
}
.voucherform .formfieldarea {
    padding: 0;
}
button.ui.button.adminvouchrbutn {
    background: #8bc34a;
    color: #fefefe;
    position: absolute;
    top: 48px;
    left: 15%;
    z-index: 1;
    padding: 7px 8px;
}
button.ui.button.adminvouchrbutn.adminvoucherupdate {
    background: #8bc34a;
    color: #fefefe;
    position: absolute;
    top: 11%;
    left: 12%;
    z-index: 1;
    padding: 7px 8px;
}

button.ui.button.adminvouchrbutn:hover{
    background: #4caf50;
}
button.ui.button.adminvouchrbutn .plus.icon {
    font-size: 13px;
}
form.ui.form .ui.grid .column.border-remove {
    box-shadow: none !important;
}
.addeditmodal-formbutn{
    text-align: right;
}
.addeditmodal-formbutn button.ui.button{
    width: 20%;
}
.ui.modal.transition.visible.active {
    width: 55%;
}
.adminvoucherform .ui.form .field>label.labelnme {
    color: #909090;
    font-size: 13px;
}
i.close.icon.closeicon {
    position: absolute;
    right: 10px;
    font-size: 12px;
    cursor: pointer;

}
.modalheader.header {
    position: relative;
}
/* Admin Voucher List end---------- */

/* TableList Form start ------------- */

.addtableservice{
    text-align: right;
}
.button.addsrvcbutn i.plus.icon {
    margin: 0 !important;
    color: #fff;
}
button.ui.button.addsrvcbutn {
    background: #8bc34a;
    color: #fff;
    padding: 8px 15px;
    border-radius: 3px;
}
h3.tableservc-heding {
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #5d5d5d;
    margin-bottom: 0px;
}
h3.networkhedng{
    margin-bottom: 10px;
}
.tableimg {
    height: 150px;
    width: 150px;
    /* margin: 0 auto; */
    position: relative;
    border-radius: 50%;
}
.profileimgcls {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
}
.imgupload {
    position: absolute;
    z-index: 8;
    top: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px #0003;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
input.filetype {
    opacity: 0;
    width: 40px;
    height: 63px;
    position: absolute;
    cursor: pointer;
    top: -23px;
    border-radius: 50%;
}
img.cameraicn {
    width: 15px;
    height: auto;
}
.imgaeuploader{
    position: relative;
}
.field.uploadfield input[type="file"] {
    border: none !important;
    padding: 0;
    position: absolute;
    /* bottom: 0; */
    opacity: 0;
    border: none !important;
    /* padding: 5px; */
    cursor: pointer !important;
    height: 100%;
    left: 0;
    margin: 0 auto;
    width: 48%;
    right: 0;
}
.uploaderarea{
    text-align: center;
    border: 1px dashed #e4e4e4;
    padding: 25px 20px;
    margin-bottom: 35px;
    background-color: #f8f8f8;
}
.uploadimgarea {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background: #eeee;
    border-radius: 50%;
    display: flex;
    align-items: center;
}
img.uploadimg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
button.ui.button.chooseimgbutn {
    cursor: pointer;
    background: #3f4257;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    border-radius: 25px;
}
button.ui.button.chooseimgbutn:hover{
    background: #272b42;
}
.uploadertext{
    margin-top: 10px;
    margin-bottom: 15px;
}
.coordinates-area {
    margin-top: 14px;
}
p.coordintetext {
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: #909090;
}
.tableservicesec {
    margin-bottom: 20px;
    margin-top: 9px;
}
.addsection{
    position: relative;
}
.column.deleicon {
    display: flex !IMPORTANT;
    justify-content: space-between;
    align-items: center;
}
.deleteservc {
    background: #f7f7f7;
    text-align: center;
    padding: 5px 8px;
    border-radius: 2px;
    cursor: pointer;
}
i.trash.trashicon {
    margin: 0;
}
/* TableList Form end ------------- */

/* Feedback List start */
.feedbacklisting {
    margin-top: 0;
    padding: 67px 0 0 0;
    height: 100vh;
    overflow-y: hidden
}
.leftsidefeedbacklist {
    width: 25%;
    max-width: 25% !important;
    height: 100%;
    border-right: 1px solid #e2e2e2 !important;
    background-color: #fbfbfb;
    padding: 0 !important;
    overflow-y: auto;
}
.detailsfeedback{
    background-color: white;
    overflow-y: auto;
    padding: 12px 15px 91px 15px !important;
}
.leftfeedbacksec{
    position: relative;
}
.feedbacklisting .container-fluid{
    padding: 0;
    height: 100%;
}
.feedbacklisting .container-fluid .row{
    margin: 0;
    height: 100%;
}
.totalfeedbacklisting{
    padding-top: 50px;
}
p.leftsidetitlesec {
    background-color: #ffa014;
    color: white;
    padding: 13px 12px;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    position: fixed;
    width: 25%;
}
span.filterimgspan {
    /* float: right; */
    height: 17px;
    width: 20px;
    cursor: pointer;
}
img.filterimg {
    height: 100%;
    width: 100%;
}
p.numbertext {
    color: #fea13e;
    font-size: 14px;
}
.feedbacknumbering {
    margin-right: 12px;
}
.feedbackcardlist {
    display: flex;
    padding: 12px;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
}
.feedbackcardlist:hover{
    background-color: white
}
p.feedbacktitle {
    margin-bottom: 2px;
    font-size: 13px;
    color: #070707;
    font-weight: 500;
}
span.feedbackdesc {
    margin-bottom: 8px;
    font-size: 13px;
    color: #6f6f6f;
    font-weight: 400;
    height: 40px;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    /* display: block; */
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    
}
p.posteddetails {
    font-size: 11px;
    color: #bbbbbb;
    font-weight: 500;
}
span.posteddate {
    color: #6f6f6f;
}
body ::-webkit-scrollbar {
    width: 3px !important;
}
body ::-webkit-scrollbar-thumb {
    cursor: all-scroll;
    border-radius: 5px;
    background: rgba(0,0,0,.25);
    transition: color .2s ease;
}
.feedbackdetailstitle {
    display: flex;
}
.feedbackdetailno {
    margin-right: 12px;
    font-size: 20px;
    color: #fea13e;
}
p.feedbackdetailtitle {
    margin-bottom: 12px;
    font-size: 20px;
    color: #272727;
    font-weight: 600;
}
p.feedbackdetaildesc {
    font-size: 15px;
    color: #6f6f6f;
    font-weight: 400;
}
.commentsection {
    background-color: #ffffff;
    position: fixed;
    width: 75%;
    bottom: 0;
    left: 25%;
    border-top: 1px solid #e2e2e2;
    padding-left: 11px;
}
form.ui.form.commentform {
    display: flex;
    width: 100%;
    align-items: center;
}
form.ui.form.commentform .field {
width: 92%;
    border: none;
    margin: 10px 0;
}
form.ui.form.commentform textarea {
    border: none;
    resize: none;
    background-color: #f9f9f9;
}
button.ui.button.commentsendbtn {
    border-radius: 50%;
    height: 45px;
    width: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
img.sendbtnimg {
    width: 23px;
    height: auto;
}
hr.dividerresponses {
    box-shadow: none;
    border: none;
    height: 1px;
    background-color: #efefef;
    margin: 20px 0;
}
p.responsetitle {
    font-size: 14px;
    color: #272727;
    font-weight: 600;
    /* margin-top: 12px; */
}
.responsesec {
    display: flex;
}
img.gitimgalign {
    height: 34px;
    width: auto;
    margin-right: 20px;
    border-radius: 50%
}
p.responsedname {
    margin: 0;
    /* font-size: 12px; */
    font-size: 14px;
    color: #070707;
    font-weight: 500;
}
p.responseddateinfo {
    margin-bottom: 10px;
    font-size: 12px;
    color: #a0a0a0;
}
span.responsedate {
    color: grey;
}
p.repliedtext {
    /* color: grey; */
    font-size: 14px;
    color: #6f6f6f;
    font-weight: 400;
}
/* Feedback List end */

/* ---------------------------------------responsive start---------------------------------- */
@media screen and (min-width:320px) and (max-width:768px){
    /* Feedback responsive list start */
    .leftsidefeedbacklist{
        max-width: 100% !important;
    }
    p.leftsidetitlesec{
        width: 100%
    }
    /* .ui.page.modals.dimmer.transition.visible.active {
        background-color: white;
    } */
    .commentsection{
        left: 0;
        width:100%
    }
    .ui.small.basic.modal.transition.visible.active {
        width: 100% !important;
        height: 100%;
    }
    .header {
        background-color: #ffa014 !important;
    }
    .ui.header.logoutmodalheader{
        background: transparent !important;
    }
    img.rightarrowimg {
        height: 14px;
        width: auto;
        margin-right: 14px;
    }
    span.backtext {
        color: white;
    }
    .image.scrolling.content {
        max-height: 81% !important;
    }
    .feedbacklisting {
        padding: 61px 0 0 0;
    }
    /* feedback responsive list end */

    /* --------list of table start------------- */

.tableliststart .profilepagestart {
    margin-top: 0;
    padding: 57px 0 0 0;
}

.tableliststart .historytablestart .payment-heading h2 {
    padding: 0 24px;
}

.tablediv {
    padding: 28px 20px 0 20px;
}
.addmodal-content .tablediv{
    padding: 0;
    margin:0;
}
.addmodal-content .addeditmodal-formbutn{
    margin: 25px 0 0;
}
i.close.icon.closeicon{
    z-index: 999;
}
.deleteservc {
    padding: 2px 4px;
    margin-left: 8px;
}
.tablediv .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    padding-left: 14px;
    border: none;
    display: unset;
}
.tablediv .MuiTypography-h6 {
    font-size: 17px;
    margin-top: 4px;
    margin-left: 13px;
}
button.ui.button.adminvouchrbutn {
    top: 51px;
    left: 43%;
    font-size: 0;
    line-height: 1;
    padding: 7px 6px;
}
button.ui.button.adminvouchrbutn.adminvoucherupdate {
    top: 8%;
    left: 30%;
}
button.ui.button.adminvouchrbutn .plus.icon {
    font-size: 12px;
    margin: 0 !important;
}
.modalheader.header{
    background-color: transparent !important
}
.tablediv .MuiToolbar-gutters .material-icons.MuiIcon-root{
    width:14px;
    height: 14px;
}
.tablediv .MuiIconButton-root {
    padding: 8px;
}
.addeditmodal-formbutn button.ui.button{
    width:auto;
    height:35px;
    line-height: 10px;
    margin: 0;
}
.adminmainstart .headerstart{
    background: white;
    box-shadow: none !important;
    padding-left: 7px;
}
.headerstart.adminheader .ui.grid{
    padding: 0;
    margin: 0
}
.adminmainstart .headerstart .four{
   background: #ffffff;
   padding: 14px 15px;
   box-shadow: 0px 0px 8px 1px #00000021 !important;
}
.adminmainstart .headerlogo{ margin-bottom:0;}
.adminmainstart .mobilenavitems ul.navullist li {
    margin-bottom: 0;
}
.adminmainstart .headerstart .twelve{
   padding: 0 !important;
   box-shadow: 0 5px 6px 0px #00000021 !important;
}
.mobilenavitems{
    background:#ffffff;
    padding: 10px;
}
.adminmainstart .navullist a {
    color: unset;
    line-height: 35px;
}
/* --------list of table end--------------- */





}




/* ---------------------------------------responsive end------------------------------------ */