/* global start*/
.textleft {
    text-align: left;
}
#root {
    height: auto; 
}
.forotherpage{
    height: auto;
}
p.cardblc b {
    color: #212121;
}

/* global end */
/* ---------------QRScannerCode css start--------------- */
.qrscanner{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-center{
    text-align: center;
}
.padding0{
    padding:0;
}
.scannerimg{
    width: 80%;
    height: auto;;
}.cardmidlearea {
    width: 100%;
    height: 100%;
    text-align: center;
}
.scannerbutnarea {
   
    margin-top: 4.8em;
}
.qrscanner button.ui.button {
    width: 65%;
    background: #1499da;
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.8px;
    font-weight: 600;
}
.scannerbutnarea button.ui.button:hover{
    background: #078fd2;
    color: #fff;
}
.checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:#ccc;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#ccc;
    left:8px;
    top:12px;
}

.completetime:after{
    content: '';
    /* display: block;
    width: 5px;
    height: 10px;
    border: solid green;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    left: 4px;
    top: 4px;
    border: 6px solid green;
    border-radius: 50%; */
    position: absolute;
    background: url(../../Images/circle.png);
    left: 4px;
    top: 4.5px;
    width: 12px;
    display: block;
    z-index: 9999999;
    height: 12px;
    background-size: cover;
}
.completetime{
    /* vertical-align: text-bottom;
    padding:3px;
    color:#ffffff; */
    color: #fff;
    /* border-radius: 2px; */
    position: relative;
    padding: 3px 9px 3px 19px;
    border-radius: 15px;
    /* background: #fff; */
    background: linear-gradient(to right, #1cce93, #62f16d);
}
.activebal {
    color: #ffffff;
    vertical-align: text-bottom;
    padding: 3px 10px;
    /* color: #010623; */
    border-radius: 15px;
    position: relative;
    background: linear-gradient(to right, rgb(238, 128, 83), rgb(241, 174, 62));
}
span.serviceactivetext{
    font-size: 11px    
}
p.headertxt {
    font-size: 15px;
    letter-spacing: 0.6px;
    color: #b9b9b9;
    text-align: left;
}
span.url {
    color: #808080;
}
.logoare {
    width: 100%;
    height: auto;
    text-align: center;
    margin-bottom: 30px;
}
.hidden-md{
    display: none;
}
.logoare img {
    width: 23%;
    height: auto;
}
.cardareadesktop {
    width: 58%;
    margin: 0 auto;
    /* height: 100%; */
    background-color: #ffffff;
    padding: 60px 30px;
    box-shadow: 0px 1px 20px #00000026;
}
/* ---------------QRScannerCode css end--------------- */

/* ---------------Select Settings css start--------------- */
.subpages{
    padding-top: 85px;
    position: relative;
}
.rangepicker {
    height: 100%;
    width: 100%;
    background: #f9f9f9;
    padding: 90px 0 35px 0 
    /* display: flex;
     align-items: center; */;
}
.range-inputfied-area {
    padding: 0 0 25px 0;
    /* box-shadow: 0px 1px 20px #00000026; */
    /* width: 80%; */
    margin: 0 auto;
}
.grouped.checkboxfield {
    margin: 2em 0 0.5em !important;
}
.checkboxfield .field {
    display: inline-block !important;
    margin: 0px !important;
}
.checkboxfield .field>label {
    display: flex !important;
    align-items: center;
    margin: 0 12px 0 0 !important;
    color: #828282 !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    letter-spacing: 0.3px;
    margin-bottom: 5px !important;
}
.checkboxfield input[type="checkbox"] {
    margin-right: 15px;
}
.emailfield p.datafield {
    margin: 2em 0;
}
label.datafield {
    margin-bottom: 0px !important;
}
.datafield, label.checkdatafield, .totalamnt {
    font-size: 14px !important;
    letter-spacing: 0.5px;
    font-weight: 600 !important;
    color: #a9a9a9 !important;
    font-family: 'Poppins', sans-serif !important;
}
span.totalamnt {
    padding-right: 8px;
    font-size: 14px !important;
    color: #b7b7b7 !important;
}
p.loacation {
    margin-bottom: 25px !important;
    display: flex;
    align-items: center;
}
.value {
    color: #696969;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
}

.shadow{
    box-shadow: none !important;
}
.leftsideimages {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}  
.leftsideimages img {
    width: 95%;
    height: auto;
}
.selectstngarea {
    padding: 25px 50px 30px;
    background: #fff;
    box-shadow: 0px 1px 20px #00000018;
}
.selectoption .ui.selection.dropdown>.dropdown.icon{
    top: 0px !important;
    line-height: 23px;
}
.selectoption .ui.dropdown>.dropdown.icon:before {
    content: '\f0d7';
    position: absolute;
    right: 8px;
}
.range-inputfied-area .selectoption {
    width: 37%;
    display: inline-flex;
    align-items: center;
    margin-bottom: 0 !important;
}
.range-inputfied-area .selectoption .ui.selection.dropdown {
    margin-left: 10px;
}
img.mapicon {
    width: 15px;
    height: auto;
    margin-right: 8px;
}
.selectsetngbutn button.ui.button {
    width: 40% !important;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
}
.range-inputfied-area .selectoption .dropdown.selection {
    min-width: 45% !important;
    max-width: 60% !important;
    min-height: 28px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
}
.range-inputfied-area .selectoption .default.text {
    font-size: 11px;
    letter-spacing: 0.5px;
}
.rangesliderfield {
    margin-bottom: 2.3em;
}
label.checkdatafield {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;
}
.rangepicker .ui.grid{
    margin-left: 0;
    margin-right: 0;
}
.selectpageheader {
    background-color: #ff98007a;
    height: 120px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.selectservicepara{
    font-size: 18px;
    font-weight: 700;
    color: #1f549f;
    margin: 0;
    padding: 7px 0 0 12px;
    letter-spacing: 0.5px;
}
/* ------------------updated select Settings start--------------------- */
.selctsettings {
    background-color: #f3f4f4;
    padding-bottom: 0;
    padding-top: 69px;
}


.selectsettingsheader {
    /* background-color: #000E59; */
    height: 18vh;
    position: relative;
    
}
.selctsettings .selectbox {
    position: absolute;
    background: #fff;
    top: 62px;
    width: 50%;
    right: 0;
    margin: 0 auto;
    left: 0;
    height: auto;
    box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    /* margin-top: 30px */
}
.selectsettngcards{
    position: absolute;
    top: 4%;
    right: 0;
    left: 0;
    
}
.selectsettngcards .selectsinglecard{
    
    background: #fff;
    
    width: 50%;
    
    margin: 0 auto;
    
    height: auto;
    box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 0px 0px 0px 0px;
    margin-top: -10px 
}
.selectbox.selectsinglecard.selectingtablediv {
    position: unset;
    margin: 0 auto;
    /* padding-bottom: 10px; */
    margin-bottom: 24px;
}
.timingarea {
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 1px 15px #0000001a;
    background: white;
    padding: 7px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

}
.timingarea ul.timingclock li{
    display: inline-block;
    list-style: none;
    vertical-align: -webkit-baseline-middle;
}

@-moz-document url-prefix() {
    .timingarea ul.timingclock li{
        list-style: none;
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        text-align: center;
    }
    
}
ul.timingclock {
    padding: 0;
    margin: 0
}
/* .selectsettingsstart.selectsetngpage{
    height: 285px;
} */
button.ui.button.serviceclosebtn {
    font-size: 11px;
    background-color: #b7b7b7;
    height: 24px;
    line-height: 0;
    color: white;
}
button.ui.button.serviceclosebtn:hover {
    background-color: #8e8d8d;
}
.inusetext {
    background: linear-gradient(rgb(241, 174, 62), rgb(243, 162, 66), rgb(243, 150, 71), rgb(241, 139, 77), rgb(238, 128, 83));
    position: absolute;
    color: white;
   padding:0 8px;
    top: 8%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5%;
    height: 23px;
}
.inusetext p {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}
p.servcestxt {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: #424242;
}
/* clockanimation start */
.timeanimation{
    /* display:flex; */
    /* justify-content:center; */
    position:relative;
    padding-right: 13px;
    height: 40px;
    }
    
    .circle, .circle2{
    /* margin-top:50px; */
    border:1px solid #b7b7b7;
    border-radius:50%;
    width:25px;
    height:25px;
    position:relative;
    animation: a 5s infinite linear ;
    top: 7px;
    }
    .circle2 {
    right: 0px;
    top: -18px;
    animation: a 10s infinite linear;
}
    .circle2 .hour{
        border:1px solid #b7b7b7;
    }
    .hour {
    position: absolute;
    top: 45%;
    left: 45%;
    border: 1px solid #b7b7b7;
    height: 9px;
    border-radius: 15%;
}
    @keyframes a{
    0%{}
    100%{transform:rotate(360deg)}
    }
/* clockanimation end */
.selectedtableimg{
    padding:10px;
    height: 100%;
    width: 40%;
    border-bottom-left-radius: 9px;
    border-top-left-radius: 9px;
    background: #f5f5f5 url('../../Images/solar-energy.png');
    background-size: 40%;
    background-position: center center;
    background-repeat: no-repeat;
    border:none;
    outline: none;
}
img.tableplaceholderimg {
    height: 100%;
    width: 100%;
}

.selectservicedrawer .selectbox {
    position: unset;
    background: #fff;
    width: 50%;
    right: 0;
    margin: 0 auto;
    height: auto;
    box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}
.selectinnerheader p {
    display: flex;
    align-items: center;
    justify-content: center;  
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 23px;
    font-family: 'Roboto',sans-serif;
    width:90%;
}
.locationheader.selectinnerheader p {
    display: unset;
}
.heading1 .credit_value{
    font-weight: 500;
    font-family: 'Lato',sans-serif;
    font-size:21px;
}
.cardblc {
    justify-content: center;
    font-size: 14px !important;
    color: #9c9b9b !important;
   
    font-weight: 400 !important;
}
.creditblnccard .cardblc {
    font-size: 14px !important;
    text-transform: uppercase;
    color: #212121 !important;
}
.spacecls {
    margin: 0 4px;
}
.fontbold {
    font-weight: 500;
    margin-right: 4px;
    color: #212121;
}
.heading1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 11px 10px 17px;
}
.heading1 p {
    color: #0c5bbb;
}
.selctlocation.servceusage {
    display: unset;
}
.selctlocation {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 12px 10px;
}
img.locationimg {
    height: 17px;
}
.locationimgdiv{
    border: 0.5px solid #fcbd37;
    background: #ffecc582;
    border-radius: 50%;
    width: 10%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;

}
img.panelimg {
    height: 16px;
}
img.wifiimg {
    height: 16px;
}
.selctlocation p {
    font-size: 14px;
        color: #212121;;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
}
.selctlocation .wifistatusonline{
    color: #0b9c0b;
    background: #daf5da;
    padding: 0px 7px;
    border-radius: 2px;
    line-height: 26px;
    font-weight: 400;
}
.selctlocation .wifistatusoffline{
    color: red;
    background: #fbe4e4;
    padding: 0px 7px;
    border-radius: 2px;
    font-weight: 400;
}
.borderdiv::after {
    position: absolute;
    content: '';
    background: #eee;
    width: 88%;
    height: 1px;
    top: 0;
    left: 8%;
}
.borderdiv::before {
    position: absolute;
    content: '';
    background: #eee;
    height: 1px;
    width: 88%;
    left: 8%;
    bottom: 0;
}
.serviceselectioncard {
    margin: 0 auto;
    width: 50%;
}
.servicesettingselectingcard {
    background-color: #f3f4f4;
    padding-bottom: 20px;
}
.borderdiv {
    position: relative;
}
.selectsettingsstart {
    height: 100vh;
}
.selectheading2 h3 {
    font-size: 21px;
    color: #333;
    margin-bottom: 11px;
    margin-left: 0;
    margin-top: 17px;
}
.selectheading2.slcthsrvchedng.selectservicecardhead h3{
    margin-bottom: 0
}
.innerbox {
    position: relative;
    padding: 11px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    color: #fff;
    background: #fff;
    box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    padding: 15px 16px 14px;
}
.selectservicedrawer .subpages {
    padding-top: 18px;
    position: relative;
}
.servicecardgroup{
    display: flex;
    width: 100%;
    overflow-x: auto;
    background-color: #f3f4f4;
    padding-bottom: 22px;
    padding-top: 10px;
    padding-left: 14px;
}
.slidergroup{
    width: 46%;
}
body ::-webkit-scrollbar {
    height: 0 !important;
}
body ::-webkit-scrollbar-thumb {
    cursor: all-scroll;
    border-radius: 5px;
    background: rgba(0,0,0,.25);
    transition: color .2s ease;
}
.serviceindividualcard {
    width: 93%;
    margin-right: 93px;
}
.innerbox p,.innerbox2 p {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 15px;
    margin-top: -6px;
    font-family: 'Roboto', sans-serif;
}
.innerwifiimg {
    margin: 10px 0 25px !important;
    width: auto;
    height: 40px;
}
.cost {
    margin-bottom: 14px !important;
    font-size: 23px !important;
    font-family: 'Lato', sans-serif !important;
}
.permin {
    font-size: 13px !important;
    margin-bottom: 2px !important;
}

.innerchrgimg {
    margin-bottom: 19px;
    width: auto;
    height: 41px;
}
.selectbtn .button.ui.button{
    background-image: linear-gradient(to right, #124296, #1047a0, #0d4cab, #0951b5, #0256c0);
    color: #fff;
    text-transform: capitalize;
    height: 50px;
    /* line-height: 50px; */
    font-size: 15px;
    border-radius: 5px;
    margin: 0;
    font-family:'Roboto' , sans-serif;
    font-weight: 500;
    box-shadow: 0px 8px 15px rgba(67, 110, 181, 0.29);
    margin-left: 0;
    text-align: center;
    width: 52%;
    margin-top: 15px;
}
.selectbtn button.ui.disabled.button {
    background-color: #dad9d9;
    color: #7f7f7f;
    opacity: 1 !important;
}

.selectbtn{
    text-align: center;
    margin: 0 7px;
}
.belowselctservicepart {
    padding: 0 7px;
}
.selectbtn .button.ui.button:hover , .selectbtn .button.ui.button:focus{
    background-image: linear-gradient(to left, #124296, #1047a0, #0d4cab, #0951b5, #0256c0);
    color: #fff;
}
.ui.grid.paddinglr >div{
    width: 50%;
}
.ui.grid.paddinglr {
    margin:0 -1rem 25px;
}
.checkimg {
    width: 19px;
    height: auto;
    position: absolute;
    top: 23px;
    right: 22px;
}
.checkimg1 {
    width: 19px;
    height: auto;
    position: absolute;
    top: 23px;
    right: 28px;
}
.checkimg.borderedcircle, .checkimg1.borderedcircle {
    height: 19px;
    width: 19px;
    border-radius: 50%;
    border: 1px solid #9a9e9f;
}
.innerbox ul{
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
}
.innerbox ul li{
  float: left;
}
.innerbox ul li:last-child{
    float: right;
    font-size:12px;
    vertical-align: baseline;
    color:#607D8B;
}

.selectbox.selectsinglecard.creditblnccard {
    position: unset;
    width: 100%;
    border-radius: 6px;

}
.creditblnccard .heading1 {
    border: none;
    padding: 16px 10px;
}
.creditblnccard .selectinnerheader p{
 font-weight: 400;
}
/* .selectedtableimg{
    height: 100%;
    width: 100%;
    padding: 10px 0 10px 12px
}
.selectedimg{
    height: 100%;
    width: 100%;
} */
.locationheader .innerdetailscard{
    padding: 0 0px 0px 0px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
}
/* ------------------updated select settings end----------------------- */
/* Updated table header */
.selectedtableinfo p.wifistatusonline {
    background-color: #83e2834a;
    color: #3b8c1e;
    padding: 5px 15px;
    border-radius: 2px;
    width:auto;
    font-size:12px;
    border: 1px solid #3b8c1e4f;
    text-transform: capitalize;
    font-weight: 400;
}

.selectedtableinfo p.wifistatusoffline {
    background-color: #ff00001a;
    color: red;
    padding: 5px 15px;
    border-radius: 2px;
    width:auto;
    font-size:12px;
    border: 1px solid #ff000040;
    text-transform: capitalize;
    font-weight: 400;
}
.selectedtableinfo p.wifistatushalf {
    background-color: #fbbb325e;
    color: #8a5d08;
    padding: 5px 15px;
    border-radius: 2px;
    width: auto;
    font-size: 12px;
    border: 1px solid #bf9f5f3d;
    text-transform: capitalize;
    font-weight: 400;
}
.selectedtableinfo p {
    font-size: 18px;
    color: #888787;
}
.selectedtableinfo {
    display: flex;
    align-items: center;
    min-height: 65px;
}
.rightsidedetailes {
    width: 60%;
    padding: 10px;
}
.selectedimg {
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 9px;
    border-top-left-radius: 9px;
}

.tabledetailssecheader {
    width: 100%;
    display: flex;
    height:220px;
    /* align-items: center; */
}
.selectedtableheadertext {
    width: 57%;
    margin: 0 auto;
}

/* ---------------Select Settings css end--------------- */

/* ---------------Payment start-------------- */
.homestart{
    background: #f3f4f4;
    height: 100%;
}
.content.active{
    border-top: 1px solid #ccc;
}


.payment-text-area {
    padding: 25px 0;
    width: 81%;
    margin: 0 auto;
}
.grouped.fields.credittype {
    display: flex !important;
}
.credittype .field {
    padding-right: 12px !important;
}
.credittype input[type="radio"] {
    margin-right: 5px;
}

.payment-text-area .content.active {
    background: #f9f9f9;
    padding: 0 !important;
}
.payment-text-area .title {
    /* box-shadow: 0 0 8px #00000012; */
    border: none;
    background: rgb(37, 93, 164) !important;
    color: #fff !important;
}
.defaultcard-area p.datafield {
    margin-bottom: 5px;
    color: #595959 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
}
.defaultcard-area  p.datafield  .value{
    color: #888888 !important;
}
h3.headingdefultcrd {
    color: #595959;
    font-size: 13px;
    margin-bottom: 1em;
}
.payment-text-area .accordion{
    margin: 0 !important;
    box-shadow: none !important;
    /* border: 1px solid #ccc; */
    border-radius: 0px !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.datepicker .react-datepicker-wrapper {
    min-width: 97%;
    margin: 0 auto;
}
.datepicker .react-datepicker__input-container {
    min-width: 100% !important;
}


.paymentarea .ui.grid{
    margin-left: 0;
    margin-right: 0;
}

        /* ----------------------updated payment start---------------------------- */

.paymentbtn {
    margin-top: 33px !important; 
    position: relative;
    text-align: center;
  }

  .paymentbtn::before {
    position: absolute;
    content: '';
    background: #f7f7f7;
    height: 287px;
    width: 1px;
    right: -39px;
}
.paymentbtn button.ui.button {
    width: 81%;
    background: rgb(107, 145, 202) !important;
    color: #ffffff;
    text-transform: uppercase;
    height: 44px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.creditcardimg {
    height: 24px;
    width: 24px;
    margin-right: 14px;
}
p.vouchertitlepara {
    width: 39%;
    margin: 0 auto;
    background-color: #232a5b;
    color: white;
    padding: 12px;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
ul.buynowcardul.flex-wrap {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
}
    .buynowpagestart p.vouchertitlepara {
        width: 100%;
        justify-content: unset;
        padding: 17px 12px;
    }

.buynowamountsec {
    /* height: 80px; */
    /* width: 80px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #f3f4f4; */
}
.buynowamountsec p {
    font-size: 25px;
    color: #0e4d9a;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
}
p.expirytext {
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
}
p.expirytext span{
    font-weight: 600
}
li.flex-item {
    height: 80px;
    width: 122px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    margin: 0 0 12px 12px;
}
img.gobackimg {
    height: 16px;
    width: auto;
    margin-right: 12px;
    cursor: pointer;
}
/* .voucher {
    border: 1px solid #f3f3f3;
    border-top: none !important;
    margin-top: 33px;
    
} */

.voucher h3 {
    background: #eee;
    color: #212121;
    padding: 10px 22px;
    border-radius: 2px;
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    justify-content: left;
    text-align: center;
}

.addnewcardbutn.vouchrbutnarea {
    
    margin-top:16px;
    margin-bottom: 36px;
    text-align: center;
}

/* .addnewcardbutn.vouchrbutnarea {
    margin-top: 24px;
    margin-left: 5px;
} */

.vouchrbutnarea button.ui.button {
    background: #ffb21b;
    color: #fff;
    width: 37%;
    height: 45px;
}

.defaultcard-area label {
    margin-bottom: 14px !important;
    font-size: 16px !important;
}

.imagevoucher {
    width: 22px;
    height: 22px;
    margin-right: 14px;
}

.addnewcard{
    padding-top: 6px;
    padding-bottom: 20px;
}

.defaultcard-area {
    width: 100%;
    padding: 0 0px;
}

.paymentarea {
    padding:44px 0;
    background: #f3f4f4;
    height: 100%;
}
.payment-heading {
    padding: 12px 13px;
}

.payment-heading h2 {
    color: #212121;
    padding-bottom: 16px;
}

.payment-btn {
    margin-top: 8px;
    padding: 0 52px;
}
.payment-btn >.column{
    padding: 0 !important;
}
/* .paymentarea .payment-heading::before {
    position: absolute;
    content: '';
    width: 14%;
    height: 2px;
    background-color: #fcb632;
    bottom: 0;
} */
.alertmodal.ui.active.modal {
    width: 35%;
}

.payment-para {
    font-size: 16px;
    color: #555;
    font-family: 'Roboto', sans-serif;
}



.paypalbtn {
    /* background: #ffffff; */
    box-shadow: none;
    /* border-radius: 8px; */
    width: 100%;
    /* height: 50px; */
    /* padding: 16px; */
    /* text-align: center; */
    /* box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.09); */
}

.paypal-icon {
    width: 138px;
    height: auto;
    padding-bottom: 0;
}

/* .payment-btn {
    display: flex;
    align-items: center;
    justify-content: center;
} */

.paymentbox {
    /* background-color: #fff; */
    width: 50%;
    right: 0;
    margin: 0 auto;
    height: auto;
    /* box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.15); */
    border-radius: 10px;
    margin-top: 77px;
}


.voucher-icon {
    width: 103px;
    height: auto;
}
.grayvoucher-icon {
    width: 35px;
    height: 35px;
}

.addnewcardbutn {
    margin-top: 34px;
    text-align: center;
}

.addnewcardbutn input[type=text] {
    height: 50px;
}

.voucherarea {
    padding: 15px 42px 20px;
    overflow: hidden;
    /* background: url('../../Images/Vouchericon.png'); */
}

.btn-area {
    padding: 30px 43px 0;
}


.vouchramntarea li{
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}
li.vouchramnt-firstlist {
    width: 87%;
}
img.checkicn {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: sub;
}

ul.vouchramntarea{
    padding: 0px;
    margin: 0;
}
/* .totalvuchramnt {
    padding: 16px 15px;
    background: #f7f7f7;
} */
p.voucheramnt-txt {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #64737d;
    letter-spacing: 0.4px;
}
.voucherimgarea {
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0 auto 30px;
}
img.vouchricn {
    width: 100px;
    height: auto;
}
h4.vouchrheding{
    text-align: center;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #475965;
}
.addnewcardbutn .ui.fluid.input {
    width: 80% !important;
    margin: 0 auto;
}
.addnewcardbutn .ui.fluid.input input[type="text"] {
    background: #f7f7f7;
    border: none;
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    font-size: 28px;
    letter-spacing: 3px;
    color: #53646f;
    height:60px;
    padding: 0;
}
.addnewcardbutn .ui.fluid.input input[type="text"]::placeholder {
    font-size:20px;
    margin-bottom: 5px;
}
.addnewcardbutn .ui.fluid.input  input[type="text"] {
    text-align: center;
}
.voucherimgarea .ui.grid>.row>.column{
    padding: 0 !important;
}
p.vouchramntdigit {
    font-size: 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #4d46c4;
}
.column.spaceremove{
    padding: 0 !important;
}
.ui.segment {
    left: 0;
    margin: 0 auto !IMPORTANT;
    /* width: 25%; */
    top: 0;
    left: 0;
    right: 0;
}
.succesful-smsbutn{
    text-align: right;
}
button.ui.button.modalokbutn{
    background: #fdb714;
    color: #fff;
}
button.ui.button.viacreditbutn {
    width: 100%;
    padding: 16px;
    background: #fff;
    box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
}
.ui.card.paymentcard {
    width: 100%;
    box-shadow: none;
    padding: 15px 20px;
    border: 1px solid #e6e6e6;
    /* height: 85px; */
    display: flex;
    align-items: center;
    justify-content: center
}
.paypalvouchr-card{
    height: 58px;
}
/* .voucherbtn {
    padding: 15px 10px;
} */
.payment-methodarea {
    padding: 30px 40px;
    background: #fff;
}
.paymntmthod-hedng {
    padding-top: 0;
}
h3.creditpaymnt {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin: 0 0 8px;
    color: #000438;
}
p.avlblc {
    font-size: 15px;
    text-transform: capitalize;
    color: #a4abbf;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.4px;
}
.avlblc span.blcamnt {
    padding-left: 5px;
    font-size: 14px;
    font-family: 'Lato',sans-serif;
    color: #555 !important;
    font-weight: 600;
}
button.ui.button.redeemvouchr-butn{
    background: #fdb714;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0.4px;
}
.voucher-modalcontent.content {
    position: relative;
}
a.ui.card.paymentcard:hover {
    box-shadow: none;
    border: 1px solid #e6e6e6;
    transform: unset;
}
.ui.card.viacreditcard {
    padding: 15px 0;
}
.ui.card.viacreditcard .ui.grid {
    width: 100%;
}
.cardpaybutn{
    text-align: right;
    height: 100%;
    margin-top: 7px;
}
button.ui.button.creditpaybutn{
    background: #fdb714;
    color: #fff;
    width: 80px
}
.ui.card.paypalvouchr-card {
    text-align: center;
}
/* ---------------Payment end-------------- */

/* -----------------Profile Start----------------------- */

.profilepage {
    width: 100%;
    padding: 0 70px 0 35px;
    position: relative;
}


.profile-heading {
    padding: 37px 85px 24px;

}
.profile-heading.hidden-xs {
    padding: 27px 85px 24px;
}



.profile-heading img {
    width: 18px;
    height: 18px;
    margin-left: 11px;
}

.profile-heading h2 {
    font-size: 23px;
    margin-bottom: 0;   
}

.profileinput {
    font-size: 15px;
    margin-bottom: 10px !important;
    color: #868686;
    font-family: 'Roboto', sans-serif;
}

.profileinput span {
    color: #212121;
    margin-left: 7px;
}

.historybutn {
    margin-top: 20px;
    
}

.historybutn button.ui.button {
    width: 26%;
    background: #f9b20d;
    color: #fff;
    text-transform: capitalize;
    height: 44px;
    font-size: 16px;
}

.profilepagestart {
    margin-top: 0px;
    padding: 67px 48px 0 48px;
    /* height: 100%; */
    
}
.forfeedback {
    padding: 67px 48px 35px 48px;
    background: #f3f4f4;
}
.feedbackpage {
    height: 100%;
}

.profileimg {
    width: 25px;
    height: 25px;
    margin-right: 28px;
}

.profilediv {
    display: flex;
    align-items: center;
}

.profiletext {
    font-size: 17px;
    color: #212121;
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.editbutton {
    position: absolute;
    right:0;
    top: 0;
    padding: 10px;
    border-radius: 5px;
}
.editbutton:hover {
    background: #f7f7f7;
}

.editimg {
    width: 13px;
    height: 13px;
    margin-right: 5px;
}

.profilebtntext {
    font-size: 15px;
    color: #0d4d9a;
    font-family: 'Roboto', sans-serif;  
}

.password {
    margin-top: 8px;
}

 

 
.editpagestart .field {
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 0;
  }
  .editpagestart label, .editpagestart input {
    transition: all 0.2s;
    touch-action: manipulation;
  }
  
  .editpagestart input, .editpagestart input[type="text"] {
    font-size: 18px;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    cursor: text;
    width: 95%;
    color: #333;
    padding: 5px;
  }
  
  .editpagestart input:focus {
    outline: 0;
    border-bottom: 1px solid #0b4d9a;
  }
  
  .editpagestart label {
    text-transform: capitalize;
    letter-spacing: 0.05em;
  }
  
  .editpagestart input:placeholder-shown + label, .editpagestart input[type="text"]:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.0);
  }
  
  /* ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }
   */

   .editpagestart input::placeholder, .editpagestart input[type="text"]::placeholder {
       opacity: 0;
       transition: inherit;
   }
  .editpagestart input:focus::-webkit-input-placeholder,  .editpagestart input[type="text"]:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  
  .editpagestart input:not(:placeholder-shown) + label,
  .editpagestart input:focus + label,
  .editpagestart input[type="text"]:not(:placeholder-shown) + label,
  .editpagestart input[type="text"]:focus + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
  }

/* ---------------------Profile End------------------------------- */



/* Map styling start */
.mapdisplay button.gm-control-active.gm-fullscreen-control {
    top: 11% !important;
}
.infowindowcontent {
    /* align-items: center;
    justify-content: center;
    display: flex; */
}
.infowindowcontent h4{
    margin: 0
}
.gm-style-iw.gm-style-iw-c {
    max-width: 1000px !important;
    max-height: 1000px !important;
    padding: 0 !important
}
.gm-style .gm-style-iw-d {
    max-width: 1000px !important;
    max-height: 1000px !important;
    overflow: unset !important;
}
.gm-style button.gm-ui-hover-effect {
    background-color: white !important;
    width: 26px !important;
    height: 26px !important;
}
.gm-style button img {
    margin: 9px 4px !important;
}
.mapmarkerdetails{
    /* padding: 5px 12px 12px 12px; */
}
.placeviewimg{
    height: auto;
    width: 230px;
}
p.placelocationtype {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    color: #a9a7a7;
    /* width: 50px; */
    font-size: 11px;
    font-family: 'Roboto',sans-serif;
    font-weight: 500;
}
img.globalicon {
    margin-right: 5px;
    width: 13px;
    height: 13px;
}
p.placenametext {
    margin: 0 0 4px 0;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    color: #0b4c99;
}
span.wifiplacestatus {
    /* background-color: #608cc3; */
    color: #037cb3;
    font-weight: 500;
    padding-left: 3px;
}
p.wifiavailability {
    font-size: 11px;
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
    letter-spacing: 0.2px;
}
.mapinfodetail {
    display: flex;
    /* align-items: center; */
    padding: 5px 12px 12px 12px;
    justify-content: space-between;
}
/* .mapdisplay div div:nth-child(2) {
    z-index: 9999999!important;
} */
p.available:after {
    content: '';
    background-color: #4ab208;
    height: 4px;
    width: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 17px;
}
p.available {
    margin: 0;
    position: relative;
    font-size: 12px;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    color: #4ab208;
}
p.inuse {
    margin: 0;
    margin: 0;
    position: relative;
    font-size: 12px;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    color: #FFC107;
}
p.inuse:after {
    content: '';
    background-color: #FFC107;
    height: 4px;
    width: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 17px;
}
p.notfunctioning {
    margin: 0;
    position: relative;
    font-size: 12px;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    color: #F44336;
}
p.notfunctioning:after {
    content: '';
    background-color: #F44336;
    height: 4px;
    width: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 17px;
}
.colorindication {
    background-color: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 12px 5px 12px;
}
.availabilityindicator,.inuseindicator {
    display: flex;
    align-items: center;
    margin-right: 4px;
}
span.availabilityicon {
    margin-right: 6px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #4ab208;
}
p.availabilitytext {
    font-size: 10px;
    font-family: 'Roboto',sans-serif;
    color: #021f42;
    font-weight: 400;
}
span.inuseicon {
    margin-right: 6px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ffc23d;
}
.notfunctioningindicator {
    display: flex;
    align-items: center;
}
span.notfunctioningicon {
    margin-right: 6px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #f4443e;
}
/* .gm-style .gm-style-iw-t::after{
    background-color: #eaeaea
} */
.markerswitchdetails {
    margin-left: 20px;
}
.tableswitch {
    display: flex;
    flex-flow: column;
}
.switchview {
 
    height: 6px;
    width: 6px;
    border-radius: 50%;
    /* margin-bottom: 5px; */
    margin-right: 3px;
    /* margin-right: 18px; */
} 
span.numberswitch {
    font-weight: 700;
    margin-right: 5px;
}
.availableswitch {
    background-color: red;
}
.inuseswitch {
    background-color: #4ab208;
}
.notfuctioningswitch {
    background-color: #ffc23d;
}
.tableswitchdetails {
    margin-left: -18px;
    margin-top: 26px;
}
.individualswitch {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    font-size: 10px;
    font-family: 'Roboto',sans-serif;
    color: #4e4e4e;
    font-weight: 400;
}

.wglobalifiavailability.mb0 {
    margin-bottom: 0;
}
/* Map styling end */

/* edit profile start */


.editprofilepage {
    padding:0px;
    width: 100%;
}
.editpagestart {
    /* margin-top: 37px; */
    padding: 55px 48px 0 48px;
    /* height: 115vh; */
    background: #f3f4f4;
}

.editprofileinput {
    font-size: 13px;
    color: #777;
}

.editprofileinput .input,.editprofileinput .dropdown {
    width: 92%;
}

.savebtn {
    text-align: right;
    padding-right: 31px;
    margin-top: 16px;
}
.savebtn button.ui.button {
    width: 20%;
    background:#006dc3 !important;
    color: #fff;
    text-transform: capitalize;
    height: 44px;
    font-size: 16px;
    margin: 0px;
}
.editpagestart .sub-inerfrm {
    border-radius: 5px;
}
.profileinner .field.datepickerfield input[type="text"] {
    padding-left: 5px !important;
}
/* ---------reset password start---------- */
/* .confrmbtn{
    text-align: left;
} */
.confrmbtn button.ui.button{
    width: 43%;
}.resetpswrd {
    padding-bottom: 68px;
}
/* ---------reset password end---------- */

/* edit profile end */

/* History table start */

.historytablestart .payment-heading {
    padding: 4px 0px 50px 4px;
}
.historytablestart .payment-heading h2{
    position: relative;
}


.historytablestart .payment-heading h2:before {
    position: absolute;
    content: '';
    background-color: #fbb83d;
    height: 4px;
    width: 7%;
    bottom: -2px;
}
.historytablestart .MuiTableCell-alignRight{
    text-align: center;
}
.historytablestart {
    height: 100%;
    margin-top: 25px;
    padding: 0 ;
}
.servcetabarea{
    width: 500px;
    margin: 0 auto;
    /* box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.15); */
    /* background: #fff; */
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto;
}
ul.srvcicntxt{
    padding: 0 0 0 0px;
    margin: 0px;
}
ul.srvcicntxt li {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}
li.wifitxt{
    padding-left: 12px;
    vertical-align: bottom;
}
/* History table end */

/* -----Alert css start---- */
.closecrossbtn {
    position: absolute;
    top: 5%;
    right: 1%;
    cursor: pointer;
}
.alertarea{
    /* padding: 67px 48px 0 48px; */
    height: 100%;
    /* background: #f8fbfe; */
}
.alert-inerarea {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alertcard-area {
    /* box-shadow: 0px 1px 20px #00000026; */
    width: 100%;
    padding: 30px;
    text-align: center;
    background: #ffffff;
}
img.successicn {
    width: 30px;
    height: 30px;
}

.checkedimg {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #71e989, #6ee27e, #6bdb73, #69d467, #66cd5c);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.alerttxt{
    margin: 20px 0 8px;
    font-size: 21px;
}
.alertbutn-area button.ui.button{
    background: #ffb21b;
    color: #fff;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}
.alertbutnarea {
    text-align: center;
}
button.ui.button.alertbutton{
    background: #ffb21b;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    width: unset;
}
.alertcontent p.alertcontextline {
    font-family: 'Lato',sans-serif;
    font-size: 15px;
    color: #929292;
    letter-spacing: 0.5px;
    text-align: center;
    margin: 13px 0 22px;
}
.alertimgarea {
    text-align: center;
}
img.alerticon {
    width: 45px;
    height: auto;
}
.alertcontent {
    text-align: center;
}
h3.alertheader {
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 500;
}
/*-----------------------Select Serrvice Drawer start------------------------  */
.selectservicedrawer {
    height: auto;
    background: #f3f4f4;
    margin-top: 68px;
    padding-top: 24px;
    padding-bottom: 24px;
}
.drawerheader {
    background: #fff;
    padding: 50px 100px;
}
.bordr{
    border-right: 1.3px solid #f4f5f5;
    padding-top: 0px !important;
    padding-bottom: 0px !important; 
}
.bordr:last-child {
    border: none;
}
.avibleblc {
    text-align: center;
}
.avibleblc h2 {
    margin-bottom: 3.5px;
    color: #5a5a5a;
}
.avibleblc span {
    color: #a8a8a8;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
}
.headingoutlet{
    color: #364149;
    letter-spacing: 0.2px;
}
.noofoutlet {
    padding: 20px 50px;
}
.chrgricn {
    width: 16px;
    height: auto;
    margin-right: 6px;
    vertical-align: sub;
}
.disablechrgricon {
    margin-right: 9px;
    vertical-align: sub;
    margin-left: 3px;
}
.hiddenCB {
    padding: 2px 0;
}

  .hiddenCB label {
    cursor: pointer;
    padding: 4px 6px;
    line-height: 190%;
    outline-style: none;
    transition: all .6s;
  }

  .hiddenCB div {
    display: inline;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .hiddenCB input[type="checkbox"],
  .hiddenCB input[type="radio"] {
    display: none;
    
  }
  
  .hiddenCB input[type="checkbox"]+label {
    border: 1px solid #eceeee;
    border-radius: 5px;
    padding: 8px 10px;
    margin-right: 6px;
    background: #fff;
    color: #7b7b7b;
    font-size: 14px;
}
  /* .hiddenCB input[type="checkbox"]+label:hover{
    background: rgba(0, 128, 128, .8);
  } */
  
  .hiddenCB input[type="checkbox"]:checked+label {
    /* border-color: #0e4d9a; */
    /* border-color: #fcb613; */
    background: #0e4e9a;
    color: #fff;
    border-color: #0e4e9a;
}
  
  .hiddenCB input[type="checkbox"]:checked+label:hover{
    background: rgba(0, 128, 128, 0, .7);
  }
  .chrging-tmendcost{
    padding: 20px 50px;
}
  .chrgngtxt {
    color: #364149;
    letter-spacing: 0.5px;
}
 .perunitprice{
    padding-left: 5px;
    color: #869ab0;
 }
 .ui.card.changercard {
    width: 100%;
    /* border-bottom: 1px solid #e9eaee; */
    border-radius: 0px;
    padding: 25px 0px;
    background: transparent;
    display: flex;
    justify-content: center;
    box-shadow: none;
}
.inercard{
    width: 100%;
    height: auto;
    margin: 10px 0;
}
.chrgrestimte {
    text-align: center;
    padding: 35px 0;
}
.chrgrestimte h2 {
    margin-bottom: 6px;
    color: #6a6a6a
}
.chrgrestimte p{
    color: #1b2831;
    font-size: 15.5px;
    font-weight: 600;
}
.wifislider{
    /* margin-top: 40px; */
    text-align: center;
}
.servcdrawrbutn{
    background: #fff;
    display: flex;
    align-items: center;
    position: unset;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 2px 0 20px 0 #00000020;
}
.wifitme h2 {
    margin-bottom: 6px;
    color: #6a6a6a;
}
.wifitme p{
    color: #1b2831;
    font-size: 15.5px;
    font-weight: 600;
}
.wifitme {
    text-align: center;
}
.paymentbtnwidth {
    width: 50%;
}
button.ui.paymentbutn {
    padding: 12px 0;
    background-color: #fcb613;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    margin: 0px !important;
    width: 100%;
}
.ui.button.paymentbutn:hover {
    background-color: #f7b210;
}
.paymentbutn .paymnttxt{
    color: #fff !important;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.8px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}
.totalprice {
    text-align: center;
    padding: 12px 0;
    width: 50%;
}
.totalprice .pricetext {
    color: #403e3e;
    font-size: 21px;
    font-weight: 500;
    margin: 0;
    font-family: 'Lato', sans-serif;
}
.totalprice span {
    font-size: 13px;
    color: #8e8e8e;
    padding-left: 4px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    font-weight: 400;
    vertical-align: text-top;
}
/* .modal {
/* .alertmodal.modal {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: auto;
    z-index: 9;
    overflow-y: scroll;
} */
.overlay{
    background-color: black;
    position: fixed;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
}
.chargerslider .rangeslider-vertical .rangeslider__handle{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcb613;
    border: none;
    box-shadow: 0 0 11px 2px #fcb613c2;
    z-index: 99999;
} 
/* .chargerslider .rangeslider-vertical .rangeslider__handle{
width: 90px;
height: 90px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
background-color: transparent;
border: none;
left: -40px; 
} */
/* .chargerslider .rangeslider__handle-label {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcb613;
    border: none;
    box-shadow: 0 0 11px 2px #fcb613c2;
} */
/* .chargerslider .rangeslider__handle-label:before {
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    content: '';
    position: absolute;
} */
.chargerslider .rangeslider-vertical .rangeslider__fill{
    background-color: #fcb613;
    border-radius:5px;
    box-shadow: -2px 0px 10px 0 #fcb6136b;
}
.chargerslider .rangeslider__handle-label {

    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chargerslider .rangeslider-vertical .rangeslider__labels{
    width: unset;
}
.chargerslider .rangeslider.rangeslider-vertical {
    box-shadow: none;
    background: #ececec;
    border-radius: 5px;
    width: 10px;
    height: 300px;
}
.chargerslider li.rangeslider__label-item {
    color: #dcdcdc;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}
.chargerslider .rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
    width: 6px;
    height: 1px;
    background: #ececec;
}
.chrgericnarea {
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0 auto;
}
img.wifiicn {
    width: 22px;
    height: auto;
    margin-right: 10px;
}
p.chargrwifitxt {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    text-transform: uppercase;
    color: #0e4e9a;
    line-height: 21px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}
.chrgerinfo {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 22px;
}
.costselect {
    width: 77%;
    height: auto;
    background: #f4f5f5;
    padding: 15px;
    border-radius: 8px;
    margin: 18px auto;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
}
    .sliderpart{
        /* border-radius: 19px; */
    /* background: url('../../Images/settingback.png') no-repeat;
    background-size: cover; */
    /* display: flex;
    align-items: center;
    justify-content: space-around; */
    display: -webkit-inline-box;
    width: 100%;
    overflow-x: auto;
    /* background-position: bottom; */
    /* height: 380px; */
    }
    .firstslider{
    margin-left: -33px;
    }
    .sliderinfocard {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 55px;
    }
    .sliderinfocard >div {
        width: 100%;
    }
    p.costslcttxt {
        color: #4dd6b3;
        font-size: 14px;
        margin-bottom: 5px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        letter-spacing: 0.5px;
    }
    h3.acostamnt {
        margin: 10px 0 0;
        font-size: 22px;
        color: #424040;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
    }
    p.totalcost {
        font-size: 14px;
        color: #797979;
        font-family: lato;
        font-weight: 500;
        letter-spacing: 0.5px;
        margin-top: 3px;
    }
    .sliderinercard{
        background: url('../../Images/settingback.png') no-repeat;
        background-position: top;
        background-size: 100% 55%;
    }
    /* .sliderinercard::after {
        content: '';
        position: absolute;
        width: 1.2px;
        height: 96%;
        background: #dcdedea6;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    } */




  /* New Select Service Drawer start */
  .ui.card.changercard .horizontalsliderversion {
    background-color: white;
    border-radius: 8px !important;
    box-shadow: 0 1px 20px #00000014;
    margin: 0 9px 14px 9px;
    padding: 20px 12px 12px 12px;
    position: relative;
}
.selectservicedrawer .ui.card.changercard.newslidercard{
    margin: 20px 0 60px 0;
}

.horizontalsliderversion .rangeslider-horizontal .rangeslider__handle:after{
    display: none;
}
.horizontalsliderversion .rangeslider-horizontal .rangeslider__handle{
    z-index: 1;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12), 0 1px 18px rgba(0, 0, 0, 0.08);
    height: 38px;
    width: 38px;
    background-image: linear-gradient(to bottom, #f0b43a, #f2ab40, #ee9948, #ef8650, #ed7e54) !important;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}
.horizontalsliderversion .rangeslider-horizontal .rangeslider__handle:focus{
    outline: none;
}
.horizontalsliderversion .rangeslider-horizontal {
    box-shadow: none;
    height: 15px;
    border-radius: 10px;
    width: 100%;
    margin: 24px 0 21px 0;
    background-color: #fadd76;
}
.horizontalsliderversion .rangeslider-horizontal .rangeslider__fill {
    /* background-color: #0e4d9a; */
    background-color: transparent;
    box-shadow: none
}
.horizontalsliderversion .rangeslider__handle-label{
    color: white;
    font-size: 9px;
    font-weight: 600;
}
.textinsidenob p {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.textinsidenob p.timeno{
    font-size: 12px
}
.horizontalsliderversion li.rangeslider__label-item {
    top: -46px !important;
    color: #9ca2be;
    font-weight: 400;
    font-size: 12px !important;
    font-family: 'Lato', sans-serif;
}
.horizontalsliderversion ul.rangeslider__labels{
    margin: 0
}
.horizontalsliderversion li.rangeslider__label-item:first-child {
    right: -14px;
    left: unset !important;

}
.newservicesliderdetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}
.newserviceinfo {
    display: flex;
    flex-flow: column;
    /* align-items: center; */
}
p.newservicetitle {
    margin: 0;
    color: #9ca2be;
    font-size: 12px;
    font-family: 'Roboto',sans-serif;
}
p.newservicedetail {
    margin-top: 3px;
    font-family: 'Roboto',sans-serif;
    color: #26356a;
    font-size: 16px;
    letter-spacing: 0;
}
.newserviceicon {
    display: flex;
    flex-flow: column;
    align-items: center;
}
img.newserviceicon {
    height: 55px;
    width: auto;
    margin-bottom: 3px;
}
.newserviceselectinfo {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}
.activerangefull15 {
    background-color: #ee9349;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 8%;
    z-index: 0;
}
.inactiverangefull15{
    background-color: transparent;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 8%;   
    z-index: 0;
}
.activerangefull30 {
    background-color: #ee9349;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 25%;
    z-index: 0;
}
.inactiverangefull30{
    background-color: white;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 25%; 
    z-index: 0;  
}
.activerangefull45 {
    background-color: #ee9349;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 41%;
    z-index: 0;
}
.inactiverangefull45{
    background-color: white;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 41%; 
    z-index: 0;  
}
.activerangefull60 {
    background-color: #ee9349;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 58%;
    z-index: 0;
}
.inactiverangefull60{
    background-color: white;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 58%; 
    z-index: 0;  
}
.activerangefull75 {
    background-color: #ee9349;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 74%;
    z-index: 0;
}
.inactiverangefull75{
    background-color: white;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    left: 74%;  
    z-index: 0; 
}
.activerangefull90 {
    background-color: #ee9349;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    right: 8%;
    z-index: 0;
}
.inactiverangefull90{
    background-color: white;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    bottom: 19%;
    right: 8%;  
    z-index: 0; 
}
  /* New Select Service drawer end */
/*-----------------------Select Serrvice Drawer end------------------------  */

    /* ----------------------history page  start------------- */

    .historydiv {
        width: 100%;
        margin: 0 auto;
        padding-top: 68px;
        z-index: 0;
        background: #f3f4f4;
        padding-bottom: 1px;
    }
    .historydiv .ui.secondary.pointing.menu{
        position: sticky;
    width: 100%;
    z-index: 999;
    top: 0;
    background: #000e59;
    }

    .historydiv .ui.secondary.pointing.menu .item {
        color: #e8e8e8;
        width: 33.3%;
        text-align: center;
        position: relative;
        font-family: 'Roboto', sans-serif;
    }
    .historydiv.voucherpage .ui.secondary.pointing.menu .item {
        width: 50%;
    }

    .historydiv .ui.menu:not(.vertical) .item {
        justify-content: center;
    }
    .historydiv .ui.segment {
       
        box-shadow: none;
        border-radius: 0;
        border: none;
        margin: 0;
        background-color: #f3f4f4;
        color: #fff;
        padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;
    }

    .historydiv .ui.menu {
        margin: 0;
        background: #0b2559;
    }
    .historydiv .ui.ui.secondary.pointing.menu .item{
        padding: 18px;
        font-size: 16px;
        border-width: 5px;
    }
    .historydiv .ui.secondary.pointing.menu a.item.active {
        border-color: #fbb631;
        font-weight: 500;
        border-width:5px;
        color:#ffffff;
    }
    .historydiv .ui.secondary.pointing.menu a.item:hover{
        color:#ffffff;
    }
    .hpaymenticon {
        width: 50px;
        height: 50px;
    }

    .historypayment {
        background-color: #fff;
        padding: 16px;
        color: #000;
        display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 10px;
        
    }

    .hpaymentdate {
        color: #909090;
         font-size: 12px;
    }

    .historyimg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
    }

    .hpaymenttext {
        display: flex;
        align-items: center;
        margin-top: 8px;

       
    }

    .hpaymenttext.paytext {
        display: flex;
        align-items: center;
        margin-top: 4px;
        margin-left: 10px;
    }

    .historyinnertext {
        font-size: 16px;
        margin-bottom: 0;
    }

    .paypal {
        display: flex;
        color: #868686;
        line-height: 19px;
    }

    .paymentamount {
        font-size: 20px;
        font-weight: 600;
        color: #626262;
        margin-bottom:2px;
        text-align: center;
        font-family: 'Lato', sans-serif;
    }
    .historyinner {
        display: flex;
        align-items: flex-start;
    }


    .hservicetdate {
        display: flex;  
        font-size: 14px;
        color: #727272;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
    }

    .hservicetext {
        margin-left: 20px;
    }

    .hservicetext .historyinnertext {
        font-size: 16px;
        /* font-family: 'Roboto', sans-serif; */
        margin-top: 4px;
    }

    .historyserviceinner {
        font-size: 13px;
        color: #969696;
        margin-top: 0;
        letter-spacing: 0;
    }

    .servicehistoryimg1 {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(to bottom, #f1ae3e, #f3a242, #f39647, #f18b4d, #ee8053);
    }
    .hserviceicon {
        width: 25px;
        height: 25px;
    }
    .hservicewifi {
        width: 20px;
        height: 20px;
    }
    .serviceusage{
        background: #fff;
        padding: 10px;
        margin-bottom: 10px;
    }
    .usageinfo{
        background: #f7f7f7;
        padding: 15px;
        font-family: 'Roboto', sans-serif;
        margin-top: 8px;
    }
    .usageinfo p{
        color: #1d1d1d;
        font-size:14px;
        text-align: left;
    }
    .usageinfo span{
        color: #0d4e9b;
        font-size:14px;
        text-align: left;
    }
    .usagecardheading{
        color:#222222;
        font-size:16px;
        font-family: 'Roboto', sans-serif;
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
        align-items: center;
    }
    .datetme{
        text-align: right;
    }
    .usetime, .usedate {
        color: #999999;
        font-size: 13px;
    }
    span.usedate {
        padding-right: 7px;
    }
    .serviceicon{
        width: 20px;
        height: auto;
        /* margin-right: 20px; */
    }
    .datendtme{
        text-align: right;
    }
    p.credit_value {
        font-size: 15px;
        font-family: 'roboto',sans-serif;
        margin: 0;
        font-weight: 400;
        letter-spacing: 0.6px;
        color: #1d1d1d;
    }
    .alignadjst{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 60px;
        max-width: 60px;
    }
    p.sapcewifipowr {
        padding-left: 14px;
    }
    p.creditblc{
        color: #0c5bbb
        }
    .wifipowrndvalue {
        width: 100%;
        /* display: flex;
        justify-content: space-between;
        height: 20px;
        align-items: center; */
        text-align: center;
    }
    p.servusnghedng {
        margin-bottom: 10px;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #121212;
        font-weight: 400;
        font-family: 'Roboto' ,sans-serif;
        }
    p.moneyvalue {
        font-size: 15px;
        color: #1d1d1d;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 29px;
        font-family: 'Lato', sans-serif;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    p.moneyvalue.totalcost{
        color: #0b2559;
        font-weight: 600;
    }
    p.duration{
        margin: 0;
        font-size: 13px;
        font-family: 'Roboto', sans-serif;
        /* color: #80dc80; */
        font-weight: 400;
        letter-spacing: 0.2px;
        color: #1d1d1d;
        line-height: 29px;
    }
    .servceusage.totalcostsection{
        border-top: 1px solid #eeeeee;
        padding-top: 5px;
        margin-top: 10px;
    }
    /* ----------------------history page  end---------------- */

    /* Add credit page css start */
    .inner-summary.subpages.addcreditpagestart {
        padding-left: 13px;
        padding-right: 13px;
    }
    .addcreditvouchersec {
        background-color: white;
        padding: 12px;
    }
    .addcreditvouchersec p.title,.addcreditcardsec p.title {
        font-size: 17px;
        font-weight: 600;
        color: #0e4d9a;
    }
    .promocodediv {
        display: flex;
        align-items: center;
    }
    .promocodediv input[type="text"] {
        box-shadow: none;
        border: 1px solid #f3f4f4;
        border-radius: 2px;
        padding: 9px 8px;
        margin-bottom: 0;
        font-size: 13px;
        width: 300px;
        background-color: #f3f4f4;
    }
    .promocodediv .ui.left.icon.input input[type="number"] {
        box-shadow: none;
        border: 1px solid #f3f4f4;
        border-radius: 2px;
        padding: 9px 8px;
        margin-bottom: 0;
        font-size: 13px;
        width: 230px;
        background-color: #f3f4f4;
    }
    .addcreditcardsec{
        position: relative;
        background-color: white;
        padding: 12px;
    }
    p.ortext {
        position: absolute;
        top: -45%;
        left: 0;
        right:0;
        margin:0 auto;
        font-size: 18px;
        color: white;
        background-color: #d4d4d4;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding: 4px; */
    }
    hr.lightborderdivider {
        border: none;
        background-color: #dcdcdc;
        height: 1px;
        margin: 30px 0;
    }
    button.ui.button.promosubmissionbtn {
        background-color: #4c4c4c;
        border-radius: 3px;
        padding: 10px 16px;
        color: white;
        font-size: 13px;
        margin-left: 7px;
    }
    /* Add credit page css end */

/*----------- Summary page start ---------------*/
button.ui.button.addcreditbalancepage {
    font-size: 12px;
    padding: 5px 13px;
    margin-right: 0;
    border-radius: 2px;
    background-color: #0e4d9a;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.summarybtncls {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.summary {
    overflow-x: hidden;
    background: #f3f4f4;
}

.sliderarea .slick-dots{
    /* top: 165px; */
    display: none !important;
}
.sliderarea {
    padding: 30px 0;
    background: white;
    /* height: 215px; */
    width: 100%;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}
p.summaryheadingcls {
    margin: 0;
    font-size: 21px;
    font-weight: 600;
    color: #0e0e0e;
    letter-spacing: 0.5px;
    font-family: 'Poppins',sans-serif;
}
.otpcontainer > div {
    font-size: 20px;
}
p.sumamrysubheadingtext {
    margin: 0;
    font-size: 15px;
    color: #8d8d8d;
    padding-top: 3px;
}
input.otpinputcls {
    width: 2.6em !important;
    border: 1px solid #e3e3e3;
    margin-right: 11px;
    margin-bottom: 0;
    border-radius: 8px;
    height: 2.5em;
    display: unset;
    background-color: #f9f9f9;
    justify-content: flex-start;
}
input.otpinputcls:focus {
    outline: none;
}
button.ui.button.submitotpbtn {
    text-align: right;
    border: 1px solid #0f4e9a;
    background: transparent;
    color: #0f4e9a;
    font-size: 14px;
    padding: 10px 28px;
    margin-right: 0;
    border-radius: 2px;
    text-transform: capitalize;
}
.buynowsection {
    background: url('../../Images/buy-voucher_02.png');
    border-radius: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* width: 100%; */
    margin: 0;
    margin-top: 10px;
    padding: 22px;
    margin-bottom: 0;
}
.buyvoucherimgdiv {
    margin-top: 10px;
    cursor:pointer;
}
img.buyvoucherimgcls {
    width: 100%;
    height: 100%;
}
.buyvouchertxt {
    margin: 0;
    font-size: 16px;
    color: #fdefeb;
    padding: 12px 0;
    font-weight: 500;
    font-family: 'roboto';
    line-height: 22px;
}
button.ui.button.addcreditbalancepage.buyvoucherbtn {
    margin-right: 0;
    background-color: #222;
    padding: 11px 17px;
    font-size: 13px;
    margin-top: 0;
}
p.buyvoucherheading {
    font-size: 21px;
    margin-bottom: 0;
    color: #fff;
    font-weight: 600;
    line-height: 22px;
    font-family: 'roboto';
}
.submitotpbtndiv {
    text-align: left;
    margin-top: 30px;
}
.creditimg {
    width: 23px;
    height: auto;
    margin-right: 8px; 
}
.otpcontainer {
    margin-top: 17px;
}
img.sumrysliderimg {
    width: 100%;
    height: auto;
    /* border-radius: 4px; */
}
.sliderarea .slick-initialized .slick-slide{
    margin-right: 7px;
    width: 400px;
}
.sliderarea .slick-list{
    padding: 0px !important;
}
.sliderarea .slick-dots li button:before{
    color: white !important;
}
ul.creditblcsec {
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
    margin: 0;
    background: #ffffff;
    /* display: flex;
    align-items: center; */
}
.backwhite{
    background: #ffffff;
    padding-bottom: 15px;
    margin-bottom: 12px;
}
ul.creditblcsec li{
    display: inline-block;
    width: 50%;
}
ul.creditblcsec li.creditblncli{
    width: 60%;
}
/* ul.creditblcsec li:last-child {
    width: 30%;
} */

li.creditblncli p.crdtblctxt {
    line-height: 35px;
    text-transform: capitalize;
    color: #373e48;
    font-size: 18px;
}
span.balenceimg {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #4b5c68;
    font-family: 'Roboto', sans-serif;
}
img.dolaricn {
    width: 22px;
    height: auto;
    margin-right: 12px;
    vertical-align: sub;
}
img.attachicn{
    width: 18px;
}
img.uaagesicn{
    width: 15px;
    vertical-align: sub;
}
p.balence {
    text-align: right;
    font-size: 27px;
    color: #fcb632;
    font-weight: 600;
    line-height: 15px;
}
.usefullnk{
    padding: 0 20px 25px;
    border-bottom: 1px solid #eee;
    background: #ffffff;
}
.usngvouchrcode {
    background: linear-gradient(to right, #6a9ce0, #69dbe0);
    padding: 12px 10px;
    text-align: center;
    border-radius: 8px;
    min-height: 120px;
    max-height: 120px;
    cursor: pointer;
}
.vouchercode2{
    background: linear-gradient(to right, #3add83, #65f36b);
}
.voucherimg {
    width: 48px;
    height: 30px;
}
.usngvouchr-txt {
    color: #fff;
    margin: 5px 0 10px;
    letter-spacing: 0.2px;
    font-size: 13px;
}
button.ui.button.vouchrbutn {
    background: transparent;
    color: #fff;
    padding: 7px 18px;
    border: 1px solid #fff;
    font-size: 12px;
}
.lastusagessec{
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
    margin: 0;
    background: #ffffff;
}
.lastusagessec .crdtblctxt{
    line-height: 10px;
}
.vouchrservcusageshedng {
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
    margin: 0;
}
p.crdtblctxt {
    /* line-height: 14px; */
    line-height: 58px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #4b5c68;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
p.vouchrsrvctxt {
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #1d1d1d;
}
p.vouchrtotlcost {
    padding-right: 16px;
    text-align: right;
    color: #0d4e9b;
    font-weight: 600;
}
.vouchrservcusageshedng li{
    display: inline-block;
    width: 50%;
}
p.vouchr-datetme {
    font-size: 12px;
    color: #1d1d1d;
    letter-spacing: 0.5px
}
span.vouchrtme {
    padding-left: 5px;
}
.vouchrservce{
    padding: 10px 15px 0;
    /* border-bottom: 1px solid #eee; */
}
.voucherinfo{
    margin: 10px 10px 0;
}
.ui.grid>.column.decreasespce{
    padding-left: 6px;
    padding-right: 6px;
}
.inner-summary{
    width: 42%;
    margin: 0 auto;
    background: #f3f4f4;
    padding-bottom: 10px;
}

/* Code scanner css start */
.scannersecdiv{
/* height: 200px;
width: 200px; */
margin: 20px 20px;
position: relative;
}
/* barcode container */
.anim-box {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 25px 30px;
	transition: transform .6s ease-out;
}

/* adds the 4 corners */
.anim-box:before, .anim-box:after, .anim-box>:first-child:before, .anim-box>:first-child:after {
	position: absolute;
	width: 10%; height: 15%;
	border-color: #fbb83d; 
	border-style: solid;
	content: ' ';
}

/* top left corner */
.anim-box:before {
	top: 0; left: 0;
	border-width: 4px 0 0 4px;
}

/* top right corner */
.anim-box:after {
	top: 0; right: 0;
	border-width: 4px 4px 0 0;
}

/* bottom right corner */
.anim-box>:first-child:before {
	bottom: 0; right: 0;
	border-width: 0 4px 4px 0;
}

/* bottom left corner */
.anim-box>:first-child:after {
	bottom: 0; left: 0;
	border-width: 0 0 4px 4px;
}

/* barcode bars */
.anim-box .scanner {
  animation-play-state: running;
}

/* animated laser beam */
.scanner {
    width: 100%;
    height: 3px;
    background-color: #fbb83d;
    opacity: 0.7;
    position: relative;
    box-shadow: 0px 0px 8px 10px rgba(251, 184, 61, 0.49);
    top: 50%;
    animation-name: scan;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  animation-play-state: paused;
}
.scanningoptiondiv{
position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@keyframes scan {
  0% {
    box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
    top: 50%;
  }  
  25% {
    box-shadow: 0px 6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 5px;
  }
  75% {
    box-shadow: 0px -6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 98%;
  }
}
/* Code scanner css end */
/*----------- Summary page start ---------------*/

/* voucher page start */
.historypayment.mb0{
    margin-bottom: 0;
    padding: 0;
}
.historypayment.mb0.bbottom1{
    border-bottom:1px dashed #cecece;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
button.ui.button.buybtnnow{
    background: #57b04a;
    /* border-radius: 30px; */
    height: 35px;
    padding: 0 15px;
    text-align: center;
    color: #ffffff;
    font-family: 'Roboto',sans-serif;
    line-height: 35px;
}
button.ui.button.creditsharebtn {
    border: 1px solid #d4d4d4;
    padding: 9px 12px;
    display: flex;
    align-items: center;
    margin-right: 12px;
    background-color: transparent
}
button.ui.button.buybtnnow.addcreditbtn{
    background: #fbb731;
    color:#ffffff;
}
.historyamount{
    display: flex;
    align-items: center
}
img.shareimg {
    width: 15px;
    opacity: 0.6;
    margin-right: 5px
}
button.ui.button.creditsharebtn {
    background-color: transparent;
}
button.ui.button.buybtnnow:hover,button.ui.button.buybtnnow:focus{
background-color: #4d9c42;
}

.ui.button.buybtnnow.addcreditbtn:hover,.ui.button.buybtnnow.addcreditbtn:focus {
    background-color: #ea9c01;
    color: #fff;
}

.bold{
    font-weight: 600;
}
.buyvoucher{
    padding: 15px;
    background: #ffffff;
    margin-bottom: 12px;
    position: relative;
}
.buyvoucher .hpaymenttext.paytext{
    margin: 0;
    display: unset
}
img.sharediconcls {
    height: 14px;
    width: 14px;
    margin-right: 4px;
}
span.vouchersharedflag {
    margin: 0;
    position: absolute;
    right: 6px;
    top: 4px;
    font-size: 13px;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #4a4949;
}
.benefits{
    color: #494848;
    font-size: 13px;
    margin: 10px 0 0 !important;
    font-family: 'Roboto',sans-serif;
}
.paymentamount.voucheramount{
    font-size: 22px;
    line-height: 8px;
    height: 10px;
}

/* .novouchersfound{
    color: black;
} */
p.novouchersfound {
    background-color: white;
    padding: 14px;
    border-radius: 5px;
    box-shadow: 0 1px 13px #00000014;
    text-align: center;
    font-size: 14px;
    color: #adadad;
    font-family: 'Lato',sans-serif;
}


/* voucher page end */

/* ---------------Responsive start--------------- */


@media screen and (min-width:320px) and (max-width:768px){
    .buyvoucherimgdiv  {
        margin: 10px;
        margin-bottom: 0;        
    }
    button.ui.button.addcreditbalancepage.buyvoucherbtn {
        padding: 12px 17px;
        width: unset;
    }
    .buynowsection {
        margin: 10px;
        padding: 15px 10px;
        margin-bottom: 0;
    }
    input.otpinputcls {
        width: 3.2em !important;
        height: 3em;
    }
    p.sumamrysubheadingtext {
        padding-top: 3px;
    line-height: 24px;
    font-size: 16px;
    }
    .buynowamountsec p {
        font-size: 22px;
    
    }
    p.expirytext {
        font-size: 12px;
    
    }
    li.flex-item {
        height: 80px;
        width: 100px;
       

    }
    p.vouchertitlepara{
        width: 100%
    }
    /* 30/8/2019 modification start */
    img.locationimg {
        width: 14px;
        height: 14px;
    }
    img.panelimg {
        height: 14px;
    }
    img.wifiimg {
        height: 14px;
    }
    .tabledetailssecheader{
        height: auto;
        min-height: 120px;
    }
    .rightsidedetailes{
        padding: 5px;
    }
    .locationimgdiv{
        border:none;
        background:none;
    }
    .selectedtableinfo{
        min-height:37px;
    }
    .selectedtableinfo p {
        font-size: 12px;
        color: #888787;
        font-weight: 400;
        line-height: 14px;
    }
    .locationimgdiv{
        margin-right: 8px;
    }
    .selectedtableimg{
        padding: 9px;
        display: flex;
        height:121px;
    }
    .selectedtableinfo p.wifistatusonline{
        line-height: inherit;
    }
    /* 30/8/2019 modification end */

    .ui.page.modals.dimmer .ui.modal.transition.visible.active{
        width:  90%;
    }
    .selectedtableheadertext {
        width: 100% !important;
        margin: 0 auto;
    }

   /*----------Select Serrvice Drawer responsive start--------  */
   .selectbtn .button.button.ui.button{
       margin: 0 auto;
       width: 100%;
   }
   .sliderpart{
       border-radius: 0
   }
   .costselect{
    background: #fff;
    box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.15);
   }
   .selectservicedrawer .ui.card.changercard {
    padding: 0;
}
.innerbox{
padding:6px 7px 8px;
}

   .drawerheader {
    padding: 45px 0px;
    }
    .avibleblc span{
        font-size: 13px;
    }
    .avibleblc h2{
        margin-bottom: 2px
    }
    .noofoutlet{
        padding: 20px 20px 20px 12px;
    }
    .chrging-tmendcost {
        padding: 0px; 
    }

    .spacing{
        padding: 0px 5px !important;
        /* padding-bottom: 0px; */
    }
    .selectservicedrawer .settingcard{
        height: 100%;
        width: 100%;
        top: 0;
        padding: 0px;
        position: unset;
        box-shadow: none;
        border-radius: 0px;
        background: #f4f5f5;
        z-index: -1;
    }
    .selectservicedrawer .selectsettingsstart {
        height: 100%;
    }
    .selectservicedrawer {
        height: 100%;
        background: #f4f5f5;
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    .servcdrawrbutn{
        position: fixed;
        z-index: 2;
    }

    .selectservicedrawer .headerlogo2, .paymentarea .headerlogo2 {
        padding: 15px 8px 15px 29px;
    }
    .submitotpbtndiv {
        margin-top: 20px;
    }
    .otpcontainer {
        margin-top: 13px;
    }
   /*----------Select Serrvice Drawer responsive end--------  */

    .hidden-xs{
        display: none;
    }
    .hidden-xs.headerxs{
        display: none !important;
    }
    .hidden-md{
        display: block;
    }
    /* ---------------QRSCanner Code responsive start--------------- */
    .qrscanner {
        display: unset;
    }
    .scannerbutnarea {
        margin-top: 50px;
        text-align: center;
    }
    .scannerheader {
        padding: 30px 15px 50px;
    }
    p.headertxt {
        text-align: center;
    }
    .inerscanner .ui.card {
        margin: 0 auto;
        border: 1px solid #eaeaea;
        padding: 60px 10px;
        border-radius: 0px;
        width: 100%;
        background-color: #f9f9f9;
        box-shadow: none;
    }
    .headerarea {
        padding: 15px 20px;
        text-align: center;
        box-shadow: 0 0 15px #00000012;
    }
    .headerarea p{
        font-size: 16px;
        letter-spacing: 0.7px;
        color: #565656;
        font-weight: 600;
    }
    p.vouchrtotlcost  {
        padding-right: 16px;
        text-align: right;
    }


    /* ---------------QRSCanner Code responsive end--------------- */
/* Map styling start */
.mapdisplay button.gm-control-active.gm-fullscreen-control {
    top: 10% !important;
}
/* Map styling end */

    /* ----------payment start----------------- */

    .paymentbtn button.ui.button {
        width: 100%;
    }

    .vouchrbutnarea button.ui.button {
        width: 100%;
        height: 44px;
        font-size: 17px;
        padding: 0;
        margin-top: 22px;
    }
    .addnewcardbutn.vouchrbutnarea {
        width: 80%;
        margin: 0 auto;

    }

    .payment-heading {
        padding: 12px 14px;
        width: 100%;
        
    }

    .payment-text-area {
        padding: 0 0;
        width: 100%;

    }

    .voucher {
        margin-top: 0;
    }
    .paymentbtn::before{
        display: none;
    }
    /* .ui.active.modal{
        width: auto;
    } */
    .alertmodal.ui.active.modal{
        width: 90%;
        overflow-y: hidden;
    }

    .alertsubtxt {
        padding: 10px 0 10px;
        font-family: 'Roboto', sans-serif;
    }
    .paymentarea {
        padding:0 0;
    
    }

    .payment-para {
        font-size: 17px;
        color: #555;
        /* position: relative; */
        /* margin-left: 14px; */
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;
    }
    /* .payment-para:before{
        position: absolute;
        content:'';
        width:5px;
        height: 5px;
        background:#555;
        border-radius: 50%;
        left:-12px;
        top:6px;
    } */
    .voucherarea {
        background-color: #fff;
        position: relative;
        padding: 30px 0;
    }

    .disableoverlay {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .btn-area {
        background-color: #f3f4f4;
        padding: 15px 0 23px;
    }
   

    button.ui.button.paypalbtn {
        width:115px;
        height:50px;
        margin: 0;
        background-color: #ffffff;
        border: 2px solid #ddd;
    }

    .paypal-icon {
        width: 85%;
        height: auto;
        padding-bottom: 0;
    }

    .paypalbtn{
        /* background: #ffffff; */
        box-shadow: none;
        border-radius: 8px;
        width: 100%;
        /* height: 50px; */
        /* padding: 15px; */
        /* text-align: center; */
        /* box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.09); */
        /* display: flex;
        align-content: center;
        justify-content: center; */
    }

    .paymentbox {
        background-color: #fff;
        width: unset;
        right: unset;
        margin: unset;
        height: unset;
        box-shadow: unset;
        border-radius: unset;
        margin-top: unset;
    }
    .voucher-icon {
        width: 100px;
        height: auto;
    }
    .ui.segment{
        width: 100% !important;
    }
    /* button.ui.button.alertbutton{
        width: 50%;
    } */
    .paymentarea .ui.grid .column.pdngbtom {
        padding-bottom: 0px;
    }
    .ui.modal.transition.visible.active .paymentsms-content.content {
        padding: 30px 20px !important;
    }
    .cardpaybutn{
        margin-top: 7px;
    }
    img.alerticon {
        width: 52px;
        height: auto;
    }
    .alertimgarea {
        margin-bottom: 10px;
    }
    .alertcontent p.alertcontextline {
        /* margin: 10px 0 15px; */
        font-size: 16px;
    }
    h3.alertheader {
        font-size: 21px;
    }
    /* .paymentcard{

    }  */


    /* ----------payment end------------------- */
       
    
    /* Profile page css start */
        .profilepagestart {
            margin-top: 0px;
            padding: 78px 0 !important;
            height: auto;
        }


        .profilepagestart .registerfrm .sub-inerfrm {
            padding: 0;
            width: 100%;
            
        }

        .profilepage {
            width: 100%;
            padding: 19px;
            margin-top: 14px;
            position: relative;
        }

        .historybutn button.ui.button {
            width: 100%; 
        }
        .profilepagestart .registerfrm{
            padding-top: 0px;
        }
    /* Profile page css end */

    /* edit profile start */

    .profile-heading {
        padding: 13px 20px 7px;
    }
    
    

    .editpagestart {
        margin-top: 0;
        padding: 0;
        /* height: auto; */
        /* height: 136vh; */
    }

    .editpagestart .registerfrm .sub-inerfrm {
        padding: 0;
        width: 91%;
        min-width: 91%;
        margin-top: 4px;
    }

    .editpagestart .registerfrm {
        padding-top: 0;
    }

    .editprofileinput .input, .editprofileinput .dropdown {
        width: 100%;
    }

    .editpagestart .ui.container {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .savebtn {
        text-align: center;
        padding-right: 0;
        margin-top: 30px;
        margin-bottom: 11px;
    }

    .savebtn button.ui.button {
        width: 100%;
    }


    .profileinput {
        margin-bottom: 0 !important;
        
    }
    .editprofilepage {
        padding: 24px 16px 16px;
        width: 100%;
        
    }
    .editpagestart input {
        width: 100%;
    }
   


    .editbutton {
        position: absolute;
        right:18px;
        top: 12px;
    }
    .profile-heading h2 {
        font-size: 20px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;

    }

    .ptb {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }


   



    /* edit profile end */


    /* ---------------Select Settings responsive start--------------- */
   
    .selectservicedrawer a.headerlogoimg img,.paymentarea a.headerlogoimg img{
        display: none;
    }
    img.actvicn {
        width: 20px;
        height: auto;
    }
    label.datafield{
        line-height: 0px;
    }
    .rangepicker{
        background: #fff;
        padding: 65px 0 35px 0;
    }
    .selectstngarea {
        padding: 0;
        box-shadow:none;
    }
    .range-inputfied-area .selectoption {
        width: 100%;
    }
    .selectsetngbutn {
        text-align: center;
    }
    .selectsetngbutn button.ui.button {
        width: 70% !important;
    }
    .range-inputfied-area .selectoption .dropdown.selection {
        min-width: 75% !important;
        max-width: 75% !important;
    }

    



    /* --------------------updated select settings responsive start---------- */

.selectheading2.slcthsrvchedng.selectservicecardhead {
    margin-left: 17px;
}
    .headerlogo2 i.dropdown.icon {
        display: none;
    }
    .headerlogo2 .ui.dropdown .menu {
        left: -4px;
        top: 30px;

    }

    .headerlogo2 .item .text {
        color: #333;
    }

    /* .headerlogo2 .ui.dropdown .menu a {
        width: 90px;
    } */
    .headerlogo2 .item {
        font-size: 15px;
        padding: 7px 15px;
    }
    .checkimg {
        width: 11%;
        height: auto;
        position: absolute;
        top: 15%;
        right: 9%;
    }
    .checkimg1 {
        width: 11%;
        height: auto;
        position: absolute;
        top: 15%;
        right: 14%;
    }
    .checkimg.borderedcircle,.checkimg1.borderedcircle {
        height: 19px;
        width: 19px;
        border-radius: 50%;
        border: 1px solid #9a9e9f;
    }


.selectsettingsheader {
    margin-top: 0;
    height: 10vh;
}

/* .selectbox {
    width: 90%;
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: -190px;
} */
.selectinnerheader p.cardblc {
    font-size: 13px !important;
}

.selectbox.selectsinglecard.creditblnccard {
    padding: 0px;
    margin-bottom: 21px;
}

.selectsettingsstart {
    height: 271px;
}
.selectheading2 {
    margin-left: 6px;
}
.headerlogo2 .menuimg {
    width: 25px;
    height: 25px;
    display: none;
}
.headerlogo2 a.headerlogoimg img{
    /* text-align: center;
    display: flex; */
    width: 180px;
    /* align-items: center;
    justify-content: center; */
}
.headerlogo2 img.headerlogoimg {
    width: 100%;
    /* margin-left: -65px; */
}
.headerlogo2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 8px;
    position: relative;
}

.headerlogo2 .dropdwnbutnarea .ui.compact.menu {
    
    background: transparent !important;
}

.headerlogo2 .usrimgarea {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.usericn.logout {
    margin-left: 22px;
    width: 20px;
    height: 20px;
}
button.ui.button.logout{
background: transparent;
padding: 0 0px 0 21px;
margin: 0
}
button.ui.button.logout img {
    width: 20px;
    height: auto;
}


.usrimgarea {
    width: unset;
    height: unset;

}

.userprofile {
    margin-left: 9px;
}
.selectheading2.slcthsrvchedng.selectservicecardhead h3{
    margin-bottom: 0
}
.selectheading2 h3 {
    font-size: 15px;
    color: #333;
    margin-bottom: 11px;
    font-weight: 400;
    margin-top: 3px;
    margin-left: 0;
    font-family: 'Roboto', sans-serif;
}

/* .selectbtnrow {
    padding: 0 20px 0 30px !important;
} */

.bottomheader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 13px;
  
    margin-top: 4px;
}

.bottommenu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.bottommenu p {
    color: #555;
    margin-top: 2px;
    font-size: 12px;
    margin: 0;
}


.innerchrgimg {
    width: auto;
    height: 41px;
}


.selctsettings {
    padding-bottom: 63px;
    padding-top: 60px;
}
.selectservicedrawer .subpages{
    padding-top: 60px;
    position: relative;
}
.innerdetailscard {
    padding:10px 0 0 0;
    border-top: 1px solid #f3f3f3;
}
.locationheader .innerdetailscard{
    padding: 0 6px 11px 10px;
    border: none;
    display: unset;
}
.borderdiv::before,.borderdiv::after{
    width: 82%;
    left: 43px;  
}
.serviceselectioncard {
    margin: 0 auto;
    width: 100%;
    background-color: #f3f4f4;
    margin-top: 20px
}
.servicesettingselectingcard {
    background-color: #f3f4f4;
    padding-bottom: 80px;
}
.selctsettings .selectbox{
/* top:20px; */
width: 92%;
}
.selectbox.selectsinglecard {
    position: unset;
    margin-top: 0px;
}
ul.timingarea {
    /* width: 92%; */
    /* bottom: 97%; */
    /* top: -32px; */
    /* position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-bottom: 0x;
    background: linear-gradient(to left, #ff9966, #ff5e62);
    padding: 0;
     text-align: center;
    border-radius: 10px 10px 0 0; */
}
.selectsettngcards {
    top: 18px;
}
span.servicecardname {
    font-size: 13px;
}
@-moz-document url-prefix() {
    .timeanimation {
        padding-right: 9px;
    }
    p.servcestxt {
        margin-right: 3px;
    }
    ul.timingclock {
        display: unset !important;
    }
}
ul.timingclock {
    display: inherit;
}

     /* --------------------updated select settings responsive end---------- */



   
    
    
    
    /* Select setting css end */
    
    /* history table start */
    .historytablestart .payment-heading h2:before {
        position: absolute;
        content: '';
        background-color: #fbb83d;
        height: 4px;
        width: 25%;
        bottom: -2px;
    }
    /* history table ned */

    .alertcard-area{
        padding: 27px 10px;
        /* width: 100%; */
    }
    img.successicn {
        width: 30px;
        height: 30px;
        
    }
/* formobileheader responsive start */

    .formobileheader.headerstart {
        top: 0 ;
        position: fixed;
        height: 60px;
        padding: 2px 15px;
        z-index: 99999;
    }
    img.arrwicn{
        width: 21px;
        height: auto;
        position: fixed;
        top: 18px;
        left: 15px;
        z-index: 9999999;
    }


    /* ----------------------history page responsive start------------- */

    .historydiv {
        margin-bottom: 63px;
        margin-top: 0;
        width: 100%;
        padding-top: 60px;
        overflow-x: hidden;
    }

    .historydiv .ui.secondary.pointing.menu .item {
        color: #e8e8e8;
        width: 33.3%;
        text-align: center;
        position: relative;
        font-family: 'Roboto', sans-serif;
    }
    .historydiv.voucherpage .ui.secondary.pointing.menu .item {
        width: 50%;
    }

    .historydiv .ui.menu:not(.vertical) .item {
        justify-content: center;
    }
    .historydiv .ui.segment {
        box-shadow: none;
        border-radius: 0;
        border: none;
        margin: 0;
        background-color: #f3f4f4;
        color: #fff;
        padding-left: 0;
        padding-right: 0;
    }

    .historydiv .ui.menu {
        margin: 0;
        background: #0b2559;
    }
    .historydiv .ui.secondary.pointing.menu a.item.active {
        /* border-color: #fbb631; */
        font-weight: 500;
        background: #000e59;
    }
    .historydiv  .ui .active.item::before{
       position: absolute;
       content:'';
       background: #ffffff;
       border-radius: 5px;
       width:100%;
       height:3px;
       left:0;
       right:0;
       margin:0 auto;
       bottom:0;
    }
    .hpaymenticon {
        width: 50px;
        height: 50px;
    }
   
    .historypayment {
        background-color: #fff;
        padding: 16px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .hpaymentdate {
        color: #909090;
         font-size: 12px;
    }
 
    .historyimg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
    }

    .hpaymenttext {
        display: flex;
        align-items: center;
        margin-top: 8px;

       
    }

    .hpaymenttext.paytext {
        display: flex;
        align-items: center;
        margin-top: 4px;
        margin-left: 10px;
    }

    .historyinnertext {
        font-size: 16px;
        margin-bottom: 0;
    }

    /* .novouchersfound{
        color: black;
    } */

    .paypal {
        display: flex;
        color: #868686;
        line-height: 19px;
    }

    .paymentamount {
        font-size: 20px;
        font-weight: 600;
        color: #626262;
        margin-bottom:2px;
        text-align: center;
        font-family: 'Lato' sans-serif;
    }
    .historyinner {
        display: flex;
        align-items: flex-start;
    }


    .hservicetdate {
        display: flex;  
        font-size: 14px;
        color: #727272;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
    }

    .hservicetext {
        margin-left: 20px;
    }

    .hservicetext .historyinnertext {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        margin-top: 4px;
    }

    .historyserviceinner {
        font-size: 13px;
        color: #969696;
        margin-top: 0;
        letter-spacing: 0;
        font-family: 'Lato', sans-serif;
    }

    .servicehistoryimg1 {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(to bottom, #f1ae3e, #f3a242, #f39647, #f18b4d, #ee8053);
    }
    .hserviceicon {
        width: 25px;
        height: 25px;
    }
    .hservicewifi {
        width: 20px;
        height: 20px;
    }
    .servcetabarea{
        width: 100%;
        margin: 0px;
    }
    .selctlocation.servceusage {
        display: flex;
    }
    /* ----------------------history page responsive end---------------- */
        /*----------- Summary responsive start ---------------*/
        p.crdtblctxt {
            font-size: 14px;  
            font-weight: 600;
    font-family: 'Poppins', sans-serif;
        }
        .summary{
            background: #f3f4f4;
        }
        .inner-summary{
            width: 100%;
            /* margin-bottom: 0px; */
            padding-top: 60px;
            margin-bottom: 70px;
        }
        .sliderarea{
            /* margin-top: 59px; */
            height: auto;
            padding: 18px;
        }
        .sliderarea .slick-initialized .slick-slide{
            width: 300px;
        }
        .inner-summary.subpages.addcreditpagestart{
            padding-top: 72px
        }
        .otpcontainer > div {
            font-size: unset;
        }
        button.ui.button.submitotpbtn { 
            width: 100%;
            text-align: center;
            font-size: 17px;
        }
        button.ui.button.addcreditbalancepage {
            width: 100%;
            margin-top: 13px;
            font-size: 15px;
            padding: 15px 0;
            justify-content: center;
        }
    
        /*----------- Summary responsive end ---------------*/

}
@media screen and (max-width:320px) {
    input.otpinputcls{
        width: 2.8em !important;
        height: 2.8em;
    }
    img.dolaricn {
        width: 20px;
        margin-right: 6px;
    }
    li.creditblncli p.crdtblctxt {
        font-size: 16px;
    }
    ul.creditblcsec li.creditblncli {
        width: 60%;
    }
    p.balence {
        font-size: 23px;
        line-height: 13px;
    }
}
@media screen and (min-width:768px) and (max-width:1024px){
    .selectedtableimg{
        width:25%;
    }
    .rightsidedetailes{
        width:75%;
    }
}
@media screen and (min-width:1440px){
    ul.creditblcsec {
        margin-top:22px;
    }
    img.dolaricn{
        vertical-align: middle;
    }
    p.vouchrtotlcost {
        padding-right: 16px;
        text-align: right;
    }
    .selectedtableheadertext {
        width: 63%;
        }

    
}
