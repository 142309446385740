.headerslidermaindiv {
    position: relative;
    background: url('../../Images/shadecharger-_vecter-v2_image.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    height: 100%;
}

.splashscreenheader {
    display: flex;
    align-items: center;
    padding: 0 17px;
    height: 10%;
}

.splashlogodiv {
    width: 158px;
    height: auto;
}

.splashscreenstart .slick-next, .splashscreenstart .slick-prev {
    display: none !important;
}

button.ui.button.registrationbutton {
    margin-bottom: 14px;
    margin-right: 0;
    border-radius: 3px;
    margin: 0;
    /* background: #fcb632; */
    background: transparent;
    color: #fff;
    padding: 10px 0;
    font-size: 15px;
    width: 100%;
    border: 1px solid #fff;
    max-width: 167px;
    width: 147px;
}

.splashscreenstart button.ui.button.landingloginbutton {
    margin: 0;
    color: #fff;
    padding: 15px 42px;
    font-size: 15px;
    width: 100%;
}

.splashscreenfooter {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    /* position: absolute; */
    width: 100%;
    height: 35%;
    padding-bottom: 19px;
    /* bottom: 0; */
    /* background-color: #000; */
    /* padding: 20px 0; */
}

.splashscreenstart {
    height: 100%;
    background-image: linear-gradient(#fbfcfe, #f8fcfd,#f6fafd,#f4f8fb,#eff7fa,#ecf4f7,#eaf3f8,#e6f1f5,#e5f0f6,#e1eef6);
}

.splashsliderdivstart {
    height: 55%;
}

.splashsliderdivstart .slick-slider, .splashsliderdivstart .slick-list, .splashsliderdivstart .slick-track {
    height: 100%;
}

.slick-slide>div {
    height: 100%;
}

.splashsliderdiv {
    display: flex !important;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
}

img.splassliderimg {
    width: 120px;
    height: auto;
}

p.splashslidertext {
    font-size: 25px;
    color: #111;
    margin-top: 23px;
    text-align: center;
    width: 66%;
    font-weight: 600;
    font-family: 'Helvetica';
}

.splashscreenstart .slick-dots li {
    width: 7px;
    height: 7px;
    background: #c7d2d8;
    border-radius: 50%;
}

.splashscreenstart li.slick-active {
    background: #fbb532;
}

.splashscreenstart .slick-dots li button:before {
    display: none;
}

.splashscreenstart .slick-dots {
    bottom: 42px;
}

button.ui.button.registrationbutton.forloginsplash {
    background: transparent;
}

@media screen and (max-width:320px) {
    .splashscreenstart .slick-dots {
        bottom: 0;
    }
    p.splashslidertext{
        font-size: 18px;
        line-height: 20px;
    }
    img.splassliderimg {
        width: 100px;
        height: auto;
    }
}
@media screen and (max-height:480px) {
    /* p.splashslidertext{
        font-size: 20px
    } */
    p.splashslidertext{
        font-size: 18px;
        line-height: 20px;
    }
    .splashscreenstart .slick-dots {
        top: 100%;
    }
    /* button.ui.button.registrationbutton.forloginsplash {
        background: #0e4d9a;
    border: none;
    }
    button.ui.button.registrationbutton.forregspalsh {
        border: none;
    background: #e69400;
    } */
}
@media screen and (min-width:750px) and (max-width:1334px) {
    p.splashslidertext {
        font-size: 18px;
    }
}