/* ////////////Logout Modal css start////////// */
.logoutmodaltxt span {
    font-size: 13px;
    color: #717171;
    font-family: 'roboto';
    letter-spacing: 0.5px;
}
.modal *{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;

}
.ui.modal>.modalbutnarea {
    border-top: none;
    background: transparent;
}
.button.yesbutn {
    border: none;
    color: #025f02;
    background: #daf5da;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
button.ui.button.cancelbutn {
    color: #7d7d7d;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
.modalshwbutn{
    background: transparent;
}

.ui.header.logoutmodalheader img.modalcontnticn {
    width: 35px;
    height: auto;
    margin-right: 8px;
}
.ui.page.modals {
    z-index: 9999999 !important;
}
.ui.modal.logoutmodal .close {
    display: none;
}
.ui.modal.logoutmodal {
    width: 28%;
}
.ui.header.logoutmodalheader span{
    font-size: 20px;
    color: #212121;
}
.ui.header.logoutmodalheader {
    border-bottom: none !important;
}
.ui.modal.logoutmodal .logoutmodaltxt.content {
    padding: 0 21px 25px!important;
    color: #868686;
}
/* ////////////Logout Modal css end////////// */

/* ////////////Logout Modal responive start////////// */
@media screen and (min-width:320px) and (max-width:767px){
    .ui.modal.logoutmodal {
        width: 88%;
    }
    .ui.modal.logoutmodal .logoutmodaltxt.content {
        padding: 0 18px 25px!important;
    }
}

/* ////////////Logout Modal responive end////////// */
