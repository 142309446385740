/* Sign up form css */
#root {
    height: 100%;
}
.homestart{
    height: 100%
}
.signupform {
    height: 100%;
    /* padding: 100px; */
    /* background: #f9f9f9; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.inerform {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    margin:  0 auto;
}
.sub-inerfrm {
    padding: 55px 40px 40px 40px;
    box-shadow: 0px 1px 20px #00000026;
    background: #fff;
    padding-right: 0px;
    height: auto;
    width: 922px;
    margin: 0 auto;
}
.loginformimg {
    height: 100%;
    width: 100%;
}
.loginformimg img {
    margin: 0 auto;
    width: 80%;
    height: auto;
}
.formfieldarea {
    width: 100%;
    /* float: right; */
    padding: 0 70px 0 35px;
    position: relative;
}
.incorrect {
    color: #ff291f;
    position: absolute;
    top: 73px;
    margin-bottom: 0;
}
.errorfield{
    position: relative;
}
p.errorpaswrd {
    color: red;
    position: absolute;
    bottom: -23px;
}
.formfieldarea .formfield .ui.left.icon.input input{
    padding: 5px !important;
}
.frm-heading {
    margin-bottom: 50px;
}
.frm-heading h2 {
    font-size: 30px;
    margin-bottom: 1px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}
.signupform form.ui.form.formfield.emailfield .field {
    margin-bottom: 2em;
}
p.fornewusr {
    /* text-align: center; */
    /* margin-top: 25px; */
    font-family: roboto;
    font-size: 15px;
    color: #8e8e8e;
    font-weight: 500;
    letter-spacing: 0.4px;
}
/* .loginformimg img {

} */
.backhomelink {
    text-align: center;
    padding-top: 15px !important;
    color: #595959;
    font-weight: 600;
    text-decoration: underline;
}
.backhomelink:hover{
    color: #1e70bf;
}
p.headerlne {
    font-size: 13px;
    color: #595959;
    letter-spacing: 0.5px;
}
p.headerlne span {
    font-weight: 600;
}
.formfield input {
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-radius: 0px !important;
    color: #8a8a8a !important;
}
.formbutn {
    /* margin-top: 50px; */
    margin-top: 30px;
}
.formbutn button.ui.button {
    width: 35%;
    background: #f9b20d;
    color: #fff;
    text-transform: capitalize;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
}
.formbutn button.ui.button:hover {
    background: #dc9000;
}
.formfield i.icon {
    left: -5px !important;
    font-size: 12px;
    color: #4a4a4a;
}
.formfield input::placeholder {
    font-size: 13px !important;
    letter-spacing: 0.5px;
}
/* .formfield .ui.left.icon.input {

} */
.formfieldarea .ui.form .field>label.labelnme {
    font-size: 15px;
    color: #0e4d9a;
    margin-bottom: 5px;
    letter-spacing: 0.2px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
p.frgtpswrdtxt {
    margin-top: -21px;
    font-size: 14px;
    color: #8e8e8e;
    font-weight: 500;
    text-align: right;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-family: 'Roboto', sans-serif;
    /* display: flex;
    justify-content: space-between; */
}
span.passwordinfo {
    font-size: 12px;
    color: #0e4d9a;
    /* background-color: #00bcd430; */
    padding: 4px 19px 4px 8px;
    position: absolute;
    letter-spacing: 0;
    bottom: -32px;
    left: 0;
}
.frgtpswrdtxt span {
    cursor: pointer;
}


.signuplink{
    color: #f9b20d;
    cursor: pointer;
}
.signuplink:hover {
    color: #0d4d9a;
}
.signupfrmimg{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.signupfrmimg img{
    float: left;
    width: 83%;
}
.backhomeline .backhomelink {
    padding-top: 0 !important;
}
.backhomeline {
    margin-top: -28px;
}
.bacbutnarea {
    display: flex;
    justify-content: center;
    align-items: center;
}
img.homeicn {
    width: 18px !important;
    margin-right: 8px;
}
p.homeicnarea {
    margin-bottom: 0 !important;
    margin-top: 15px !important;
}
/*---------------------- signup start----------------------- */
.field.datepickerfield {
    padding: 0;
}
.datepickerfield i.calendar.icon{
    display: none;
}
.field.datepickerfield input[type="text"] {
    padding: 5px;
}
.registerfrm .sub-inerfrm {
    padding: 34px 40px 40px 40px;
    width: 955px;
}
.registerfrm .frm-heading{
    margin-bottom: 30px;
}
.registerfrm form.ui.form.formfield.emailfield .field{
    margin-bottom: 1em;
}
.registerfrm .formbutn {
    /* margin-top: 50px; */
    /* margin-top: 45px; */
}
.signupbackbutn p.homeicnarea {
    margin-top: 4px !important;
}
.registerfrm .logoare {
    margin-bottom: 22px;
}
.ui.form .field.disabled :disabled {
    opacity: 2.80 !important;
}
.registerfrm.mobileviewclass{
    height: 112vh;
}
/*---------------------- signup end----------------------- */

/* -------------feedback start--------------------------- */
.titlefild {
    margin-bottom: 33px !important;
}
.feedbacksection .formfield {
    padding: 20px 20px 0;
}
.formbutn.feedbackbtn button.ui.button {
    width: unset;
    padding: 0 34px;
    height: 38px;
}
.feedbackformfld input[type=text] {
    
    color: #222222 !important;
    font-size: 15px;
}
.feedbackformfld .ui[class*="left icon"].input>input {
    padding-left: 0 !important; 
}

.close-feedback {
    position: absolute;
    right: 12px;
    top: 11px;
    cursor: pointer;
}
.closeicon {
    width: 11px;
    height: 11PX;
}
.feedbackpage .sub-inerfrm.signin-subfrm {
    position: relative;
}
/* -------------feedback end----------------------------- */
/*---------------------- forgot password----------------------- */
.forgotpaswrd .sub-inerfrm {
    width: 45%;
    /* padding: 0; */
}
/*---------------------- Responsive start----------------------- */
@media screen and (min-width:320px) and (max-width:768px){
    .hidden-xs {
        display: none !important;
    }
    /* .mobileviewclass.signinform{
        display: flex;
        padding: 0 12px;
    } */
    /* .sub-inerfrm.signin-subfrm {
        min-width: 90%;
        max-width: 90%;
    } */
    .signinform .logoare {
        padding-top: 0;
    }
    .logoare{
        /* padding: 30px 0; */
        /* margin: 0px;
        position: absolute;
        top: 85px; */
    }
    p.fornewusr {
        text-align: center;
    }
    .mobileviewclass{
        background-image: url('../../Images/backgroundimg.png');
        background-size: cover;
        background-position: bottom;
        /* display: block; */
        height: 100%;
        /* position: relative; */
    }

    /*------------ signin rsponsive start--------------- */
    .registerfrm.mobileviewclass{
        height: 100%;
    }
    .incorrect {
        top: -28px;
        font-size: 13px;
    }
    .signupform .sub-inerfrm {
        padding: 40px 25px;
    }

    .signupform.registerfrm {
        display: block;
        align-items: unset;
        justify-content: unset;
    }
    .sub-inerfrm, .registerfrm .sub-inerfrm {
        min-width: 90%;
        max-width: 90%;
        box-shadow: none;
        /* padding-left: 25px;
        padding-right: 25px;
        padding-top: 20px; */
        border-radius: 8px;
        box-shadow: 5px 5px 10px #00000015;
        padding: 20px 25px;
    }
    .formbutn{
        margin: 25px 0;
    }
    .formbutn button.ui.button {
        width: 100%;
        height: 48px;
    }
    .logoare img {
        width: 60% !important;
        height: auto;
    }
    .formfieldarea {
        padding: 0;
    }
    img.homeicn {
        width: 16px !important;
        margin-right: 7px;
    }

    /*------------ signin rsponsive end--------------- */

/* -------------feedback start--------------------------- */

.feedbacksection .formfield {
    padding: 20px 0px 0;
}
.feedbacksection .sub-inerfrm {
    margin-top: 18px;
    padding: 20px 0 20px 0;
}
.signupform.signinform.feedbacksection {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
    padding: 78px 0 78px;
}
/* -------------feedback end----------------------------- */

    /*------------ forgot password rsponsive start--------------- */
    .forgotpaswrd .sub-inerfrm {
        /* width: 90%; */
        padding: 20px;
    }
    .forgotpaswrd .frm-heading h2 {
        text-align: center;
        font-size: 23px;
    }
    .forgotpaswrd .logoare {
        /* top: 0;
        left: 0; */
        position: unset;
    }
    h2.forgotpswrdhedng {
        font-size: 25px;
        text-align: center;
    }
    /*----------- forgot password rsponsive end--------------- */

        /*------------ signup rsponsive start--------------- */
        .registerfrm .sub-inerfrm {
            min-width: 97%;
        }
        .registerfrm .logoare {
            padding: 20px 0;
            margin-bottom: 0;
        }
    /* .registerfrm {
        padding-top: 40px;
    } */

    .hidden-md p.backhomelink {
        text-align: left;
        padding-left: 2px;
        display: flex;
        align-items: center;
    }
    /* .signupform {
        background: transparent;
    } */
}
/* @media screen and (min-width:768) and (max-width:1024px){
    .sub-inerfrm{
        width: 100%;
    }
} */
